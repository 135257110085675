import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import { BmMarker, BmLabel } from "vue-baidu-map";
import axios from "axios";
import moment from "moment";
import cancelreasonModal from "./../components/cancelReason.vue";
import {
  apply,
  getCity,
  getArea,
  getMarketCenter,
  getStreet,
  save,
  dimission,
  getParams,
  getMerchant,
  getMerchant2,
  getMerchantDetail,
  getBsc,
  getPrincipal,
  getPrincipalDetail,
  getSupplier,
  getWlz,
  getStore,
  getSalesman,
  getMarketingCenter,
  getMarketingCenter2,
  getMarketingCenter3,
  getOperator,
  getDetail,
  queryMatklByFx,
  getBscList,
  queryOffice,
  getMatklList,
  getMatklListNew,
  getfullname,
  stepBuildStoreParamCheck,
  getBDList,
  getBUList
} from "./api";
import { publicPath } from "@/common/constant.js";
import {
  getStoreUser,
  setStoreUser,
  setStoreUser1,
  delStoreUser,
  delStoreUser1,
  setzongnian,
  getzongnian,
  setmendian,
  getmendian,
  getimgurl,
  setimgurl,
  getfuzeren,
  setfuzeren,
  
} from "@/utils/store";
import { mixin } from "./index-mixin";
import BMap from '@/views/distributorWarehouse/components/BMap/index'


let isBooth = null;
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export default {
  name: "index",
  mixins: [mixin],

  components: {
    BaiduMap,
    cancelreasonModal,
    BmMarker,
    BmLabel,
    BMap,
  },
  data() {
    return {
      xiangqingdata:{},
      visible2: false,
      msgTips: "",
      selctRow: "",
      uploadUrl: publicPath + "/comm/method=uploadFile.nd",
      type: "add", // add edit
      chakanid: "",
      isview: false,
      customer: "",
      visible: false,
      bscManagerId: "", // 办事处经理id
      content: "", // 申请原因
      mapShow: false,
      center: "",
      gdCenter: "",
      zoom: 15,
      BMap: null,
      isLoginModalShow: false, //弹出模态框
      getindex: "", //传递index
      urlArr: [], //门店照片路径
      gapTime: 0, //判断是刷新还是关闭页面的时间
      beforeUnloadTime: 0, //判断是刷新还是关闭页面的时间
      isbianji: false, //是不是点击编辑
      gobacknext: false,//点击上一步显示弹窗
      gobacknext1:false,//单独建店点击上一步显示弹窗
      // 面包屑数据
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页",
        },
        {
          path: this.$route.path === '/jurisdiction/storeAddEdit' ? "/jurisdiction/store" : "/basicInfo?type=7",
          title: this.$route.path === '/jurisdiction/storeAddEdit' ? "管辖的门店" : "分销的门店",
        },
        {
          // path: "/distributionStores/addDistributionStore",
          // name: "distributionStores/addDistributionStore",
          title: "新增/编辑/查看门店",
        },
      ],

      spinning: false, //页面整体loading
      editCount: 0, //表单编辑次数 >1则有编辑
      menuList: [
        {
          title: "基础信息",
          active: true,
        },
        {
          title: "门店属性",
          active: false,
        },

        {
          title: "物料组合作关系",
          active: false,
        },
      ],
      isFixed: false, //左侧是否跟随
      formData: {},
      sessionData: [], //浏览器缓存的数据
      branchOfficeid: "", //分公司id
      // TradesessionData:[],
      afterCustomer: "", //商家值
      beforedata: true, //存储浏览器数据之前
      affterdata: false, //存储浏览器数据之后
      shichangleve: "", //市场级别
      cityleve: "", //城市级别
      youbian: "", //邮编
      addressHx: [], //地址
      addressHx1: [],
      bianjiaddressHx1: [], //编辑之后所在地区默认值
      appendixFileList: [], // 三证合一
      tijiaoable: false, //提交禁用
      previewVisible: false,
      previewImage: "",
      fileList: [],
      isfullname:false,//门店全称是否存在
      materialObj:"",
      
      //表单验证
      rules: {
        ownedBusiness: [
          {
            required: true,
            message: "请选择所属商家",
            trigger: ["change", "blur"],
          },
        ],
        marketLevel: [
          {
            required: true,
            message: "请选择市场级别",
            trigger: ["change", "blur"],
          },
        ],
        storeFullName: [
          {
            required: true,
            message: "请输入门店全称",
            trigger: ["change", "blur"],
          },
        ],
        storeShortName: [
          {
            required: true,
            message: "请正确输入门店简称",
            trigger: ["change", "blur"],
            max: 10,
          },
        ],
        province: [
          {
            required: true,
            message: "请选择地区",
            trigger: ["change", "blur"],
          },
        ],
        // city: [{
        //     required: true,
        //     message: "请选择市",
        //     trigger: ["change", "blur"],
        // }],
        // area: [{
        //     required: true,
        //     message: "请选择区县",
        //     trigger: ["change", "blur"],
        // }],
        // street: [{
        //     required: true,
        //     message: "请选择乡镇街道",
        //     trigger: ["change", "blur"],
        // }],
        // marketLv: [{
        //     required: true,
        //     message: "请选择市场级别",
        //     trigger: ["change", "blur"],
        // }],
        isTobeHxStore: [
          {
            required: true,
            message: "请选择是否成为海信专卖店",
            trigger: ["change", "blur"],
          },
        ],
        address: [
          {
            required: true,
            message: "请选择门店地址",
            trigger: ["change", "blur"],
          },
        ],
        addrDescribe: [{
            validator: this.checkAddrDescribe,
            trigger: ["change", "blur"],
        }],
        postCode: [
          {
            required: true,
            message: "请输入邮政编码",
            trigger: ["change", "blur"],
          },
          {
            validator: this.isPostCode,
            message: "请输入正确的邮政编码",
            trigger: ["change", "blur"],
          },
        ],
        cityLv: [
          {
            required: true,
            message: "请选择城市级别",
            trigger: ["change", "blur"],
          },
        ],
        storefront: [
          {
            required: true,
            message: "请选择店面性质",
            trigger: ["change", "blur"],
          },
        ],
        // isSink: [
        //   {
        //     required: true,
        //     message: "请选择是否下沉渠道",
        //     trigger: ["change", "blur"],
        //   },
        // ],
        isSpecialShop: [
          {
            required: true,
            message: "请选择是否海信专卖店",
            trigger: ["change", "blur"],
          },
        ],
        storeCategory: [
          {
            required: true,
            message: "请选择门店类别",
            trigger: ["change", "blur"],
          },
        ],
        isCore: [
          {
            required: true,
            message: "请选择是否核心门店",
            trigger: ["change", "blur"],
          },
        ],
        principalName: [
          {
            required: true,
            message: "请选择片区负责人",
            trigger: ["change", "blur"],
          },
        ],
        isBooth: [
          {
            required: true,
            message: "请选择是否做展台",
            trigger: ["change", "blur"],
          },
        ],
        content: [
          {
            required: true,
            message: "请输入申请原因",
            trigger: ["change", "blur"],
          },
        ],
        // ManagerName: [{
        //     required: true,
        //     message: "请输入经理姓名",
        //     trigger: ["change", "blur"],
        // }],
        //  ManagerPhone: [{
        //     required: true,
        //     message: "请输入经理电话",
        //     trigger: ["change", "blur"],
        // }],
        marketingCenterId: [
          {
            required: true,
            message: "请选择营销中心办事处",
            trigger: ["change", "blur"],
          },
        ],
        projectRequirements: [
          {
            required: false,
            message: "请输入项目需求名称",
            trigger: ["change", "blur"],
          },
        ],
        circleType: [
          {
            required: true,
            message: "请选择商圈类型",
            trigger: ["change", "blur"],
          },
        ], //商圈类型
        bdId: [
          {
            required: true,
            message: "请选择BD",
            trigger: ["change", "blur"],
          },
        ],
        bu1Id: [
          {
            required: true,
            message: "请选择BU",
            trigger: ["change", "blur"],
          },
        ],
        store3Size: [
          {
            required: true,
            message: "请选择门店三大品类年规模",
            trigger: ["change", "blur"],
          },
        ], //门店三大品类年规模
        brandSettled: [
          {
            required: true,
            message: "请选择友商品牌进驻",
            trigger: ["change", "blur"],
          },
        ], //友商品牌进驻
      },
      jlnamerules: {
        StoreManagerName: [
          {
            required: true,
            message: "请选择经理姓名",
            trigger: ["change", "blur"],
          },
        ],
      },
      wuliaorules: {
        MaterialGroup: [
          {
            required: true,
            message: "请选择物料组",
            trigger: ["change", "blur"],
          },
        ],
        Office: [
          {
            required: true,
            message: "请选择所属办事处",
            trigger: ["change", "blur"],
          },
        ],
        // MarketingCenterOffice: [{
        //         required: true,
        //         message: "请选择营销中心办事处",
        //         trigger: ["change", "blur"],
        //     }],
        StoreLevel: [
          {
            required: true,
            message: "请选择门店级别",
            trigger: ["change", "blur"],
          },
        ],
        StoreManagerTel: [
          {
            required: true,
            message: "请输入门店经理电话",
            trigger: ["change", "blur"],
          },
          {
            min: 11,
            max: 11,
            message: "请输入正确的门店经理电话",
            trigger: "blur",
          },
        ],
        StoreManagerName: [
          {
            required: true,
            message: "请输入门店经理姓名",
            trigger: ["change", "blur"],
          },
        ],
        StoreBoothArea: [
          {
            required: true,
            message: "请输入门店展台面积",
            trigger: ["change", "blur"],
          },
        ],
        Boothlinearmeter: [
          {
            required: true,
            message: "请输入展台延米",
            trigger: ["change", "blur"],
          },
        ],
        AnnualSalesScale: [
          {
            required: true,
            message: "请输入年销售规模",
            trigger: ["change", "blur"],
          },
        ],
        EstimatedStoreSize: [
          {
            required: true,
            message: "请输入预计门店规模",
            trigger: ["change", "blur"],
          },
        ],
        NumberOfPrototypeBits: [
          {
            required: true,
            message: "请输入样机位个数",
            trigger: ["change", "blur"],
          },
        ],
      },

      scrollWatch: true, //滚动flag
      info: "",
      storeDemandIdx: [],
      storeDemand: [
        {
          title: "需求子单号",
          dataIndex: "orderNo",
          width: 150,
        },
        {
          title: "物料组",
          dataIndex: "wlz",
          width: 150,
          scopedSlots: { customRender: "storeDemand" },
        },
        {
          title: "分销网络规模",
          dataIndex: "netSize",
          width: 150,
          scopedSlots: { customRender: "netSize" },
        },
        {
          title: "所属办事处",
          dataIndex: "bsc",
          width: 120,
        },
        {
          title: "需求类型",
          dataIndex: "demandType",
          width: 120,
          scopedSlots: { customRender: "demandType" },
        },
        {
          title: "派单类型",
          dataIndex: "dispatchType",
          width: 120,
          scopedSlots: { customRender: "dispatchType" },
        },
        {
          title: "供应商",
          dataIndex: "supplier",
          width: 220,
          scopedSlots: { customRender: "supplier" },
        },
        {
          title: "费用预估(元)",
          dataIndex: "costEstimate",
          width: 120,
          scopedSlots: { customRender: "costEstimate" },
        },
        {
          title: "要求完成时间",
          dataIndex: "time",
          width: 120,
          scopedSlots: { customRender: "time" },
        },
        {
          title: "说明",
          dataIndex: "illustrate",
          width: 120,
          scopedSlots: { customRender: "illustrate" },
        },
        {
          title: "展台使用年限(年)",
          dataIndex: "usePeriod",
          width: 240,
          scopedSlots: { customRender: "usePeriod" },
        },
      ], // 门店需求
      storeDemands: [], // 门店需求
      selectedRowKeys: [],
      selectedRowKeyscypl: [],
      bscIdx: [], // 办事处下标
      bsc: [
        {
          title: "所属办事处",
          dataIndex: "bsc",
          width: 200,
          scopedSlots: { customRender: "bsc" },
        },
        {
          title: "分公司名称",
          dataIndex: "company",
          width: 200,
          scopedSlots: { customRender: "" },
        },
        {
          title: "品牌",
          dataIndex: "category",
          width: 200,
          scopedSlots: { customRender: "category" },
        },
        {
          title: "物料组",
          dataIndex: "wlz",
          width: 200,
          scopedSlots: { customRender: "wlz" },
        },
        {
          title: "是否主销楼层",
          dataIndex: "isMainFloor",
          width: 200,
          scopedSlots: { customRender: "isMainFloor" },
        },
        {
          title: "位置排名",
          dataIndex: "locationRanking",
          width: 200,
          scopedSlots: { customRender: "locationRanking" },
        },
        {
          title: "紧临品牌",
          dataIndex: "closedBrand",
          width: 200,
          scopedSlots: { customRender: "closedBrand" },
        },
        {
          title: "计划配置促销员人数",
          dataIndex: "peopleNum",
          width: 200,
          scopedSlots: { customRender: "peopleNum" },
        },
        {
          title: "促销员编码/姓名",
          dataIndex: "promoter",
          width: 200,
          scopedSlots: { customRender: "promoter" },
        },

        {
          title: "门店级别",
          dataIndex: "storeLevel",
          width: 200,
          scopedSlots: { customRender: "storeLevel" },
        },
        {
          title: "所属运营商",
          dataIndex: "operator",
          width: 200,
          scopedSlots: { customRender: "operator" },
        },
        // {
        //     title: "是否品牌园",
        //     dataIndex: "brandPark",
        //     width: 200,
        //     scopedSlots: { customRender: "" }
        // },
        {
          title: "所在楼层",
          dataIndex: "floor",
          width: 200,
          scopedSlots: { customRender: "floor" },
        },
        {
          title: "门店展厅面积",
          dataIndex: "area",
          width: 200,
          scopedSlots: { customRender: "area" },
        },
        {
          title: "展台延米(m)",
          dataIndex: "ztym",
          width: 200,
          scopedSlots: { customRender: "ztym" },
        },
        {
          title: "样机位数量",
          dataIndex: "num",
          width: 200,
          scopedSlots: { customRender: "num" },
        },
        {
          title: "门店经理姓名",
          dataIndex: "manager",
          width: 200,
          scopedSlots: { customRender: "manager" },
        },
        {
          title: "门店经理电话",
          dataIndex: "managerPhone",
          width: 200,
          scopedSlots: { customRender: "managerPhone" },
        },
        {
          title: "是否海信专卖店",
          dataIndex: "managerPhone",
          width: 200,
          scopedSlots: { customRender: "managerPhone" },
        },
        {
          title: "营销员姓名/编码",
          dataIndex: "salesman",
          width: 200,
          scopedSlots: { customRender: "salesman" },
        },
        {
          title: "营销员电话",
          dataIndex: "salesmanPhone",
          width: 200,
          scopedSlots: { customRender: "" },
        },
        {
          title: "进驻时间",
          dataIndex: "settlingTime",
          width: 200,
          scopedSlots: { customRender: "" },
        },
        {
          title: "冻结时间",
          dataIndex: "freezeTime",
          width: 200,
          scopedSlots: { customRender: "" },
        },
        {
          title: "通信品牌信联",
          dataIndex: "brand",
          width: 200,
          scopedSlots: { customRender: "brand" },
        },
        {
          title: "是否发送SAP",
          dataIndex: "isSap",
          width: 200,
          scopedSlots: { customRender: "isSap" },
        },
        {
          title: "是否进驻",
          dataIndex: "isEnter",
          width: 200,
          scopedSlots: { customRender: "isEnter" },
        },
        {
          title: "是否TOP客户加盟店",
          dataIndex: "isTop",
          width: 200,
          scopedSlots: { customRender: "isTop" },
        },
        // {
        //     title: "发货分公司",
        //     dataIndex: "shippingCompany",
        //     width: 200,
        //     scopedSlots: { customRender: "shippingCompany" }
        // },
        {
          title: "补货商家",
          dataIndex: "merchant",
          width: 200,
          // scopedSlots: { customRender: "merchant" }
        },
        {
          title: "合作状态",
          dataIndex: "cooperationStatus",
          width: 200,
          scopedSlots: { customRender: "cooperationStatus" },
        },
        {
          title: "冻结凭证",
          dataIndex: "freezeVouchers",
          width: 200,
          scopedSlots: { customRender: "freezeVouchers" },
        },
        {
          title: "营销中心办事处",
          dataIndex: "marketingCenter",
          width: 200,
          scopedSlots: { customRender: "marketingCenter" },
        },
      ], // 办事处
      bscList: [],
      bscInfos: [], //办事处信息
      xsgmIdx: [],
      xsgm: [
        {
          title: "物料组",
          dataIndex: "wlz",
          width: 200,
          scopedSlots: { customRender: "wlz" },
        },
        {
          title: "年销售规模（万元）",
          dataIndex: "nxsgm",
          // width: 400,
          scopedSlots: { customRender: "nxsgm" },
        },
        {
          title: "预计门店规模（万元）",
          dataIndex: "yjmdgm",
          // width: 400,
          scopedSlots: { customRender: "yjmdgm" },
        },
      ], //销售规模
      visiblecangku: false, //弹窗
      visibledata: false, //弹窗
      xsgmList: [],
      MarketTableList: [],
      tableData: [], // 物料组数据
      // 物料合作关系
      MaterialCooperationData: [],
      //缓存的物料合作关系
      huancunMaterialCooperationData: [],
      isShow: false, //是否展示
      lctableData: [], // 临促数据
      TselectedRowKeys: [],
      lcselectedRowKeys: [],
      banshichu: [], //办事处
      banshichuId: "", //办事处编码
      getMarketingCenterOffice: [], //营销中心办事处
      MarketingCenterOfficeid: '', //营销中心办事处id
      StorelevelId: "", //门店级别id
      ownedBusiness: [], //所属商家列表
      marketLevels: [], //市场级别
      storeFullNames: [], //门店全称
      storeShortNames: [], //门店简称
      zhanshi: true, //弹窗是否展示
      jlnamespan: false, //门店经理姓名为空提示
      fileIds: [], //门店照片id
      isshangchuan: false, //禁用图片上传

      province: [], //省
      provinceStr: "", //
      city: [], //市
      cityStr: "",
      area: [], //区
      areaStr: "",
      street: [], //街道
      streetStr: "",
      // marketLv: [],//市场级别
      cityLv: [], //城市级别

      storefront: [], //店面性质
      storeCategory: [], //门店类别
      principal: [], //片区负责人
      isCore: [], //是否核心店铺
      isSpecialShop: [], //是否海信专卖店
      isEnter: [], //是否入住
      djFlag: [], //冻结
      isSink: [], //是否下沉
      isBooth: [], //是否做展台
      isTop: [], //是否top客户加盟店
      storeLevel: [], //门店级别
      merchantDetail: "", //商家详情
      marketingCenter: "", //营销中心
      brand: [], //通信品牌信联
      isSap: [], //是否发送sap
      demandType: [], //需求类型
      dispatchType: [], //派单类型
      wlz: [], //
      salesman: [], //营销员
      marketingCenterBsc: [],
      operator: [], //所属运营商
      supplier: [], //供应商
      category: [],
      cooperationStatus: [], //合作状态
      bscWlz: [], //办事处物料组 code name bsc
      appendixFileId: {
        id: "",
      }, //门店上传照片
      final1: [], //最终传给后端的物料合作关系
      final2: [], //点击建仓确定缓存的数据
      isEdit: false, //物料合作关系编辑
      EditIndex: "", //编辑获取对应行下标
      Materialgroupdropdown: [], //物料组下拉菜单
      Location: "", //所在地区
      zongnianxs: 0, //总年销售规模
      zongyjmd: 0, //总预计门店
      getLocation: "", //得到所在地区

      circleType: [], //商圈类型
      BDList: [], // BD列表
      BUList: [], // BU列表
      store3Size: [], //门店三大品类年规模
      brandSettled: [], //友商品牌进驻
      isMainFloor: [], //是否主销楼层
      closedBrand: [], // 紧临品牌
      promoter: [], //促销员
      isStandard: [], //是否达标
      // 物料数据存储
      AllMaterialCooperationData: {
        MaterialGroup: "", //物料组
        MaterialGroupcode: "", //物料组code
        Office: "", //所属办事处
        MarketingCenterOffice: "", //营销中心办事处
        StoreLevel: "", //门店级别
        StoreManagerTel1: "", //门店经理电话1
        StoreManagerTel: "", //门店经理电话
        StoreManagerName1: "", //门店经理姓名1
        StoreManagerName: "", //门店经理姓名
        StoreBoothArea: "", //门店展台面积
        Boothlinearmeter: "", //展台延米
        AnnualSalesScale: "", //年销售规模
        EstimatedStoreSize: "", //预计门店规模
        NumberOfPrototypeBits: "", //样位机个数
      },
    };
  },
  computed: {
    // ManagerName() {
    //   return this.AllMaterialCooperationData.StoreManagerName1;
    // },
    // ManagerTel1() {
    //   return this.AllMaterialCooperationData.StoreManagerTel1;
    // },
  },
  watch: {
    'AllMaterialCooperationData.StoreManagerName1'(val) {
      this.AllMaterialCooperationData.StoreManagerName = val;
    },
    'AllMaterialCooperationData.StoreManagerTel1'(val) {
      this.AllMaterialCooperationData.StoreManagerTel = val;
    },
    "$route.query.type": {
      handler(val){
        this.rules.shopExpectedOpeningTime = val == "edit" ? null : [
          {
            required: true,
            message: "请选择预计开业时间",
            trigger: ["change", "blur"],
          },
        ]
      },
      immediate: true,
    }
  },

  mounted() {
    $(window).on("scroll", this.handleScroll);
    // //判断页面是否刷新--方法1
    // window.addEventListener("beforeunload", () => this.beforeunloadHandler());
    // window.addEventListener("unload", () => this.unloadHandler());
    //判断页面是否刷新--方法2
    if (window.name == ""&&this.$route.query.category!='step') {
      delStoreUser();
      delStoreUser1();
      window.name = "isReload"; // 在首次进入页面时我们可以给window.name设置一个固定值 
    } else if (window.name == "isReload"&&this.$route.query.category!='step') {
     
      delStoreUser()
      delStoreUser1()
    }
   
    this.$nextTick();
    this.getqueryMatklByFx();
    this.customer = this.$store.state.user.userInfo.customer;
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
    if (this.$route.query.id) {
      this.chakanid = this.$route.query.id;
      if (this.chakanid) {
        this.isview = true;
      }
    }
    if (this.type == "add") {
      this.formData = {
        znxsgm: 0, //总年销售规模(万元)：
        zyjmdgm: 0, // 总预计门店规模(万元)：
        isBooth: "", //是否做展台
        cis: "",
        mdm: "",
        ownedBusiness: "", //所属商家
        storeFullName: "", //门店全称
        storeShortName: "", //门店简称
        marketLevel: "", //市场级别
        province: "", //省
        city: "",
        area: "",
        street: "",
        // marketLv: '', //市场级别
        address: "",
        addrDescribe: "", //地址描述
        marketingCenter: "", //营销中心
        marketingCenterId: "",
        postCode: "", //邮编
        cityLv: "", //城市级别
        storefront: "", //店面性质
        isSink: "", //是否下沉渠道
        storeCategory: [], //门店类别
        from: "信天翁", //来源
        isTobeHxStore: "", // 是否计划成为海信专卖店
        channelCategory: "", //渠道类别
        channelCategoryId: "",
        channelCategorySub: "", //渠道类别细分
        channelCategorySubId: "",
        labelLv1: "", //集团一级标签
        labelLv1Id: "",
        labelLv2: "", //集团二级标签
        labelLv2Id: "",
        isCore: "", //是否核心门店
        isSpecialShop: "", //是否海信专卖店
        principal: "", // 片区负责人
        principalName: undefined, //片区负责人姓名
        principalPhone: "", //片区负责人电话

        headquartersMarketCategory: "", //总部市场类别
        projectRequirements: "", //项目需求名称：
        planProject: "展台制作费-非专卖店", //计划项目名称：
        content: "", //申请原因

        circleType: "", //商圈类型
        bdId: "", //BD
        bu1Id: "", //BU
        shopExpectedOpeningTime: null, // 预计开业时间
        store3Size: "", //门店三大品类年规模
        brandSettled: "", //友商品牌进驻
        isStandard: "", //是否达标
        standardDescribe: "", //达标描述
      };
      // apply().then((res) => {
      //   this.province = res.data.provicesList;
      // });
      this.getParams();
    }
    // if (this.type == "edit") {
    //   apply().then((res) => {
    // this.province = res.data.provicesList;
    // this.getDetail();
    //   });
    // }
    // 获取编辑信息
    this.getParams().then(res => {
      this.$nextTick(()=> {
        if (this.type != "add"||this.$route.query.id) {
          this.getDetail().then(res => {
          if (this.type == "edit") {
            this.goGetMatklList()
          }
        });
       }
        })
    });


    this.getBDList();
    this.sessionData.push(getStoreUser());
    // 一步建店 获取营销中心办事处
       if(
        this.$route.query.category == 'step' && 
        this.sessionData[0] && 
        this.sessionData[0].distributorInfoVO && 
        this.sessionData[0].distributorInfoVO.tradeRelationVOList && 
        this.sessionData[0].distributorInfoVO.tradeRelationVOList.length > 0 
       ){
          const materialGroupBranchCompanyStr = this.sessionData[0].distributorInfoVO.tradeRelationVOList.map(j=>j.materialGroupBranchCompany).toString();
          this.getMarketingCenterFun2(materialGroupBranchCompanyStr)
       }
    if (this.sessionData[0].showSelect && !this.isview) {
      var data = this.sessionData[0].distributorInfoVO;
      this.affterdata = true;
      this.beforedata = false;
      this.afterCustomer = data.customerFullName;
      this.Location =
        data.provinceId + data.cityId + data.countyId + data.townId;
      this.shichangleve = data.marketLevelId;
      this.cityleve = data.districtType;
      this.youbian = data.postalCode;
      this.Materialgroupdropdown = data.tradeRelationVOList;
      // this.urlArr=getimgurl()
      this.addressHx = [
        data.provinceId,
        data.cityId,
        data.countyId,
        data.townId,
      ];
      if (this.sessionData[0].custShopInfoCheckDto != undefined) {
        var data1 = this.sessionData[0].custShopInfoCheckDto;
        this.formData.storeFullName = data1.fullName;
        this.formData.storeShortName = data1.searchTerm;
        this.formData.address = data1.shAddress;
        this.formData.addrDescribe = data1.addressDesc;
        this.formData.circleType = data1.tradingTypeId;
        this.formData.bdId = data1.bdId ? data1.bdId + "" : "";
        this.formData.shopExpectedOpeningTime = data1.shopExpectedOpeningTime;
        this.getBUList(this.formData.bdId);
        this.formData.bu1Id = data1.bu1Id ? data1.bu1Id + "" : "";
        this.formData.principal = data1.areaLeaderId;
        this.formData.principalName = getfuzeren().fuzeren;
        // this.formData.isCore = data1.isCoreShop;
        // this.formData.isSink = data1.isSinkChannel;
        this.formData.isSpecialShop = data1.isPlanSpecial;
        this.formData.content = data1.content;
        this.AllMaterialCooperationData.StoreManagerName1 =data1.managerNameNew;
        this.AllMaterialCooperationData.StoreManagerTel1 = data1.managerTelNew;
        this.fileIds[0] = data1.headFilesStr;
        //门店照片上传
        if (data1.headFilesStr && data1.headFilesStr.id) {
          this.fileList.push({
            uid: data1.headFilesStr.id,
            name: data1.headFilesStr.attachName,
            url: getimgurl().imgurl[0],
            name2: "旧的",
          });
          // 门店照片上传id
          this.fileList.id = data1.headFilesStr.id;
        }
        var newMaterialCooperationData = [];
        getmendian().forEach((i) => {
          newMaterialCooperationData.push({
            materialGroupBranchCompany1: i.materialGroupBranchCompany1,
            materialGroupBranchCompany: i.materialGroupBranchCompany,

            orgId: i.orgId,
            banshichuId: i.banshichuId,

            // terminalOrgId: i.terminalOrgId, // 确认不需要
            // yingxiaoid: i.yingxiaoid,// 确认不需要

            shopLevel: i.shopLevel,
            shopLevelId: i.shopLevelId,

            managerName: i.managerName,
            managerTel: i.managerTel,
            shopArea: i.shopArea,
            singleMeter: i.singleMeter,
            annualSales: i.annualSales,
            forcastSales: i.forcastSales,
            modelCount: i.modelCount,
          });
        });
        this.MaterialCooperationData = newMaterialCooperationData;
        this.zongnianxs = this.MaterialCooperationData.reduce((v, i) => {
          return (v += Number(i.annualSales));
        }, 0);
        this.zongyjmd = this.MaterialCooperationData.reduce((v, i) => {
          return (v += Number(i.forcastSales));
        }, 0);
      }
    }

    if (this.sessionData[0]&&this.sessionData[0].distributorInfoVO.tradeRelationVOList[0]) {
      this.branchOfficeid = this.sessionData[0].distributorInfoVO.tradeRelationVOList[0].branchOfficeid
    }
  },
  destroyed() {
    this.scrollWatch = false;
    // window.removeEventListener("beforeunload", () =>
    //   this.beforeunloadHandler()
    // );
    // window.removeEventListener("unload", () => this.unloadHandler());
  },
  methods: {
    // 获取BD列表
    getBDList() {
      getBDList().then((res) => {
        if (res.data.code == 0) {
          this.BDList = res.data.list;
        }
      });
    },
    changeBD(e) {
      this.getBUList(e);
      this.formData.bu1Id = "";
    },
    // 获取BU列表
    getBUList(id){
      getBUList(id).then((res) => {
        if (res.data.code == 0) {
          this.BUList = res.data.list;
        }
      });
    },
    storefrontChange(e){
      if(e == '2091884'){
        this.formData.isSpecialShop = '0';
      }
    },
    isSpecialShopChange(e){
      if(e == '1'){
        this.formData.storefront = '2091883'
      }
    },
    //所属商家发生改变
    goGetMatklList() {
      // debugger
      //得到物料组
      // getMatklListNew().then((res) => {
      //   this.Materialgroupdropdown = res.data.list.map((i) => {
      //     i.fullName = i.name;
      //     return i;
      //   });
      //   // debugger
      // });
      getMatklList(this.formData.ownedBusiness).then((res) => {
        this.Materialgroupdropdown = res.data.list;
      });
    },
    getDetail() {
      if (this.$route.query.id) {
        return getDetail(this.$route.query.id).then((res) => {
          if(res.data && res.data.code == 1){
            return this.$message.warning(res.data.msg||'查询失败');
          }
          let info = res.data.data;
           // 获取营销中心办事处
          this.getMarketingCenterFun(info.custInfoId);
          this.infoAll = info
          //门店照片上传
          if (info.attachs[0] && info.attachs[0].id) {
            this.fileList.push({
              uid: info.attachs[0].id,
              name: info.attachs[0].attachName,
              url: info.attachs[0].attachPath,
              name2: "旧的",
            });
            // 门店照片上传id
            this.fileList.id = info.attachs[0].id;
          }
          if (this.$route.query.type == "edit") {
            this.isbianji = true;
            this.isview = false;
          }

          this.addressHx1 = [
            String(info.provinceId),
            String(info.cityId),
            String(info.countyId),
            String(info.townId),
          ];

          this.bianjiaddressHx1 = [
            String(info.provinceId),
            String(info.cityId),
            String(info.countyId),
            String(info.townId),
          ];
          let znxsgm = 0;
          let zyjmdgm = 0;
          let xsgmList = [];

          if (info.custShopInfoSalesCheckList) {
            for (let item of info.custShopInfoSalesCheckList) {
              // znxsgm += Number(item.annualSales);
              znxsgm = this.accAdd(znxsgm, item.annualSales);
              // zyjmdgm += Number(item.forcastSales)
              zyjmdgm = this.accAdd(zyjmdgm, item.forcastSales);
              xsgmList.push({
                wlzName: item.matklName,
                id: item.id,
                isNewRow: false,
                wlz: item.matklId + "",
                nxsgm: item.annualSales,
                yjmdgm: item.forcastSales,
              });
            }
            this.xsgmList = xsgmList;
          }
          let storeDemands = [];
          if (info.supplyDetailList) {
            for (let item of info.supplyDetailList) {
              storeDemands.push({
                orderNo: item.supplyDetailCode,
                id: item.id,
                isNewRow: false,
                wlz: item.baseMatklId, //物料组
                wlzName: item.baseMatklName,
                bsc: item.custShopInfoDetailCheckDto.orgName, //所属办事处
                demandType: item.supplyTypeId + "", //需求类型
                dispatchType: item.isHeaderUnion + "", //派单类型
                supplier: item.supplierCode, //供应商
                supplierName: item.supplierName,
                costEstimate: item.estimatedCost, //费用预估
                time: item.finishDate, //要求完成时间
                illustrate: item.remark, //说明
              });
            }
            this.storeDemands = storeDemands;
          }
          if (info.isUpStandard == 1) {
            info.isUpStandard = "是";
          }
          if (info.isUpStandard == 0) {
            info.isUpStandard = "否";
          }
          this.formData = {
            znxsgm, //总年销售规模(万元)：
            zyjmdgm, // 总预计门店规模(万元)：
            isBooth: info.isSubmitSupplyId + "", //是否做展台
            isBoothText: info.isSubmitSupplyText, //是否做展台
            cis: info.cisCode,
            mdm: info.mdmCode,
            ownedBusiness: info.custInfoId, //所属商家
            ownedBusinessText: info.custInfoId_name, //所属商家
            storeFullName: info.fullName, //门店全称
            storeShortName: info.searchTerm, //门店简称
            marketLevel: info.marketLevelId + "", //市场级别
            address: info.mapAddress,
            addrDescribe: info.addressDesc, //地址描述
            marketingCenter: info.marketCenterText, //营销中心
            marketingCenterId: info.shopInfoDetailCheckList && info.shopInfoDetailCheckList.length > 0 ? info.shopInfoDetailCheckList[0].terminalOrgId + '' : '', // info.marketCenterId.toString(),
            postCode: info.postalCode, //邮编
            cityLv: info.districtType, //城市级别
            storefront: info.shopPropertyId + "", //店面性质
            isSink: info.isSinkChannel, //是否下沉渠道
            storeCategory: info.shopCategory, //门店类别
            from: "信天翁", //来源
            isTobeHxStore:
              info.isPlanSpecial != null ? info.isPlanSpecial + "" : "",
            channelCategory: "", //渠道类别
            channelCategoryId: "",
            channelCategorySub: "", //渠道类别细分
            channelCategorySubId: "",
            labelLv1: "", //集团一级标签
            labelLv1Id: "",
            labelLv2: "", //集团二级标签
            labelLv2Id: "",
            isCore: 0, //是否核心门店 == 1?'是' :'否'
            principalPhone: info.areaLeaderId, //片区负责人电话
            headquartersMarketCategory: "", //总部市场类别
            projectRequirements: info.projectSupplyName, //项目需求名称：
            planProject: "展台制作费-非专卖店", //计划项目名称：
            content: info.content, // 申请原因
            // principalName: info.areaLeaderId,//片区负责人

            circleType: info.tradingTypeId + "", //商圈类型
            bdId: info.bdId ? info.bdId + "" : "",
            bu1Id: info.bu1Id ? info.bu1Id + "" : "",
            shopExpectedOpeningTime: info.shopExpectedOpeningTime,
            // circleType: info.tradingTypeName, //商圈类型
            principalName: info.areaLeaderId_name, //片区负责人
            isSpecialShop: info.isPlanSpecial, //是否海信专卖店
            store3Size: info.threeScaleId + "", //门店三大品类年规模
            // brandSettled: info.friendBrandId[0] ? info.friendBrandId[0] : '', //友商品牌进驻
            isStandard: info.isUpStandard, //是否达标
            standardDescribe: info.noStandardReason, //达标描述
          };
          this.getBUList(this.formData.bdId);
          this.AllMaterialCooperationData.StoreManagerName1 = info.shopInfoDetailCheckList && info.shopInfoDetailCheckList.length > 0 ? info.shopInfoDetailCheckList[0].managerName : '';
          this.AllMaterialCooperationData.StoreManagerTel1 =info.shopInfoDetailCheckList && info.shopInfoDetailCheckList.length > 0 ? info.shopInfoDetailCheckList[0].managerTel : '';
          info.shopInfoDetailCheckList.forEach((i) => {
            this.MaterialCooperationData.push({
              ...i,
              id:i.id,
              materialGroupBranchCompany1: i.baseMatklName + "-" + i.fgsName,
              shopLevel: i.shopLevelName.slice(0, 5),
              shopLevelId: i.shopLevelId,
              orgId: i.orgName,
              materialGroupBranchCompany:i.orgId,
              // terminalOrgId: i.terminalOrgName,  // 确认不需要
              managerName: i.managerName,
              managerTel: i.managerTel,
              shopArea: i.shopArea,
              singleMeter: i.singleMeter,
              modelCount: i.modelCount,
              annualSales: i.annualSales,
              forcastSales: i.forcastSales,
              salesId:i.salesId,
              // yingxiaoid:i.terminalOrgId,  // 确认不需要
              infoObj: i
            });
          });
          this.zongnianxs = this.MaterialCooperationData.reduce((v, i) => {
          return (v += Number(i.annualSales));
        }, 0);
        this.zongyjmd = this.MaterialCooperationData.reduce((v, i) => {
          return (v += Number(i.forcastSales));
        }, 0);
       

          if (info.isSubmitSupplyId == 14923253081) {
            this.rules = {
              ownedBusiness: [
                {
                  required: true,
                  message: "请选择所属商家",
                  trigger: ["change", "blur"],
                },
              ],
              marketLevel: [
                {
                  required: true,
                  message: "请选择市场级别",
                  trigger: ["change", "blur"],
                },
              ],

              storeFullName: [
                {
                  required: true,
                  message: "请输入门店全称",
                  trigger: ["change", "blur"],
                },
              ],
              storeShortName: [
                {
                  required: true,
                  message: "请正确输入门店简称",
                  trigger: ["change", "blur"],
                  max: 10,
                },
              ],
              province: [
                {
                  required: true,
                  message: "请选择所在地区",
                  trigger: ["change", "blur"],
                },
              ],
              city: [
                {
                  required: true,
                  message: "请选择市",
                  trigger: ["change", "blur"],
                },
              ],
              area: [
                {
                  required: true,
                  message: "请选择区县",
                  trigger: ["change", "blur"],
                },
              ],
              street: [
                {
                  required: true,
                  message: "请选择乡镇街道",
                  trigger: ["change", "blur"],
                },
              ],
              // marketLv: [{
              //     required: true,
              //     message: "请选择市场级别",
              //     trigger: ["change", "blur"],
              // }],
              address: [
                {
                  required: true,
                  message: "请选择门店地址",
                  trigger: ["change", "blur"],
                },
              ],
              addrDescribe: [{
                  validator: this.checkAddrDescribe,
                  trigger: ["change", "blur"],
              }],
              //     AddressDescription: [{
              // required: true,
              // message: "请输入地址描述",
              // trigger: ["change", "blur"],
              // }],
              //         ManagerName: [{
              //     required: true,
              //     message: "请输入经理姓名",
              //     trigger: ["change", "blur"],
              // }],
              //  ManagerPhone: [{
              //     required: true,
              //     message: "请输入经理电话",
              //     trigger: ["change", "blur"],
              // }],
              marketingCenterId: [
                {
                  required: true,
                  message: "请选择营销中心办事处",
                  trigger: ["change", "blur"],
                },
              ],
              isTobeHxStore: [
                {
                  required: true,
                  message: "请选择是否成为海信专卖店",
                  trigger: ["change", "blur"],
                },
              ],
              postCode: [
                {
                  required: true,
                  message: "请输入邮政编码",
                  trigger: ["change", "blur"],
                },
                {
                  validator: this.isPostCode,
                  message: "请输入正确的邮政编码",
                  trigger: ["change", "blur"],
                },
              ],
              cityLv: [
                {
                  required: true,
                  message: "请选择城市级别",
                  trigger: ["change", "blur"],
                },
              ],
              storefront: [
                {
                  required: true,
                  message: "请选择店面性质",
                  trigger: ["change", "blur"],
                },
              ],
              // isSink: [
              //   {
              //     required: true,
              //     message: "请选择是否下沉渠道",
              //     trigger: ["change", "blur"],
              //   },
              // ],

              storeCategory: [
                {
                  required: true,
                  message: "请选择门店类别",
                  trigger: ["change", "blur"],
                },
              ],
              // isCore: [
              //   {
              //     required: true,
              //     message: "请选择是否核心门店",
              //     trigger: ["change", "blur"],
              //   },
              // ],
              principalName: [
                {
                  required: true,
                  message: "请选择片区负责人",
                  trigger: ["change", "blur"],
                },
              ],
              isBooth: [
                {
                  required: true,
                  message: "请选择是否做展台",
                  trigger: ["change", "blur"],
                },
              ],
              content: [
                {
                  required: true,
                  message: "请输入申请原因",
                  trigger: ["change", "blur"],
                },
              ],
              projectRequirements: [
                {
                  required: true,
                  message: "请输入项目需求名称",
                  trigger: ["change", "blur"],
                },
              ],
              circleType: [
                {
                  required: true,
                  message: "请选择商圈类型",
                  trigger: ["change", "blur"],
                },
              ], //商圈类型
              bdId: [
                {
                  required: true,
                  message: "请选择BD",
                  trigger: ["change", "blur"],
                },
              ],
              bu1Id: [
                {
                  required: true,
                  message: "请选择BU",
                  trigger: ["change", "blur"],
                },
              ],
              store3Size: [
                {
                  required: true,
                  message: "请选择门店三大品类年规模",
                  trigger: ["change", "blur"],
                },
              ], //门店三大品类年规模
              brandSettled: [
                {
                  required: true,
                  message: "请选择友商品牌进驻",
                  trigger: ["change", "blur"],
                },
              ], //友商品牌进驻
            };
          }
         
          getMerchantDetail(info.custInfoId).then((res) => {
            let merchantDetail = res.data.data;
            this.formData.channelCategory = merchantDetail.primaryChannelText;
            this.formData.channelCategoryId = merchantDetail.primaryChannelId;

            this.formData.channelCategorySub = merchantDetail.secondChannelText;
            this.formData.channelCategorySubId = merchantDetail.secondChannelId;

            this.formData.labelLv1 = merchantDetail.primaryLabelText;
            this.formData.labelLv1Id = merchantDetail.primaryLabelId;

            this.formData.labelLv2 = merchantDetail.secondLabelText;
            this.formData.labelLv2Id = merchantDetail.secondLabelId;
          });
          getBsc(this.formData.ownedBusiness).then((res) => {
            this.bscInfos = res.data.list;
            let bscList = [];
            let bscWlz = [];
            for (let item of info.shopInfoDetailCheckList) {
              bscWlz.push({
                bsc: item.orgId,
                bscName: item.orgName,
                code: item.baseMatklId + "",
                name: item.baseMatklName,
              });
              let data = {
                id: item.id,
                isNewRow: false,
                bsc: item.orgId, //所属办事处
                bscId: item.orgId, //所属办事处
                bscName: item.orgName || "", //所属办事处
                company: item.fgsName || "", //分公司名称
                companyId: item.orgId, //分公司名称
                category: item.brandId && item.brandId + "", //品类
                wlz: item.baseMatklId + "", //物料组
                wlzName: item.baseMatklName || "",
                storeLevel: item.shopLevelId + "", //门店级别
                operator: item.runCustTypeName || "", //所属运营商
                operatorId: item.runCustTypeId || "", //所属运营商
                brandPark: "", //是否品牌园
                floor: item.shopFloor || "", // 所在楼层
                area: item.shopArea || "", //门店展厅面积
                ztym: item.singleMeter, //展台延米
                num: item.modelCount || "", //样机位数量
                manager: item.managerName || "", //门店经理姓名
                managerPhone: item.managerTel || "", //门店经理电话
                salesman: item.sellerId, //营销员姓名/编码
                salesmanText: item.sellerName || "",
                salesmanPhone: item.sellerTel || "", //营销员电话
                settlingTime: item.enterTime || "", //进驻时间
                freezeTime: item.djTime || "", //冻结时间
                brand: item.netStandardId && item.netStandardId + "", //通信品牌信联
                isSap: item.isToSap, //是否发送SAP
                isEnter: item.isEnter,
                isTop: item.isTopShop,
                // shippingCompany: '',//发货分公司
                merchant: item.warehouseName || "", //补货商家
                merchantId: item.warehouseId || "", //补货商家
                cooperationStatus: item.djFlag, //合作状态
                freezeVouchers: item.djVouchers, //冻结凭证
                marketingCenter: item.terminalOrgId && item.terminalOrgId + "", //营销中心办事处
                file: [],
                isMainFloor: item.isMainFloor, //是否主销楼层
                locationRanking: item.placeRank, //位置排名
                closedBrand: item.closedBrandId + "", // 紧临品牌
                peopleNum: item.cxPeopleCount, //计划配置促销员人数
                promoter: item.cxPeopleId, //促销员
                promoterName: item.cxPeopleName,
              };
              if (item.djVouchers[0]) {
                data.file = [
                  {
                    name: item.djVouchers[0].attachShortName,
                    id: item.djVouchers[0].id,
                  },
                ];
              }
              bscList.push(data);
            }
            this.bscList = bscList;
            this.bscWlz = bscWlz;
          });

          for (let i = 0; i < info.shopInfoDetailCheckList.length; i++) {
            let item = info.shopInfoDetailCheckList[i];
            // getWlz(this.customer.id, item.orgId).then(res => {
            //     this.bscList[i].wlzList = res.data.list
            //     this.bscList[i].wlz = info.shopInfoDetailCheckList[i].baseMatklId + ''
            //     this.$forceUpdate()
            // })
          }
          if (info.shopInfoDetailCheckList[0]) {
            // getWlz(this.customer.id, info.shopInfoDetailCheckList[0].orgId).then(res => {
            //     this.wlz = res.data.list
            // })
            // getMarketingCenter(info.shopInfoDetailCheckList[0].orgId).then(
            //   (res) => {
            //     this.marketingCenterBsc = res.data.list;
            //   }
            // );
            // data = {
            //     searchstr: '',
            //     officeId: info.shopInfoDetailCheckList[0].orgId
            // }
            // getSalesman(data).then(res => {
            //     this.salesman = res.data.list
            // })
          }

          let data = {
            proviceId: info.provinceId,
          };
          getCity(data).then((res) => {
            this.city = res.data;
          });
          data = {
            cityId: info.cityId,
          };
          getArea(data).then((res) => {
            this.area = res.data;
          });
          data = {
            districtId: info.countyId,
          };
          getStreet(data).then((res) => {
            this.street = res.data;
          });
          this.formData.province = info.provinceId;
          this.formData.city = info.cityId;
          this.formData.area = info.countyId;
          this.formData.street = info.townId;
          this.provinceStr = info.provinceName;
          this.cityStr = info.cityName;
          this.areaStr = info.countyName;
          this.streetStr = info.townName;
          data = {
            searchstr: "",
          };
          getPrincipal(data).then((res) => {
            if (res.data.code == 0) {
              this.principal = res.data.list;
              this.formData.principal = info.areaLeaderId;
              // this.formData.principalName = info.areaLeaderId_name
            }
          });
        });
      }
    },
    // 地址信息
    onAddressChange(addressValArr) {
      this.formData.province = addressValArr[0];
      this.formData.city = addressValArr[1];
      this.formData.area = addressValArr[2];
      this.formData.street = addressValArr[3];
    },
    //获取门店物料组
    getqueryMatklByFx() {
      queryMatklByFx().then((res) => {});
    },
    //  新的门店照片上传
    handleCancel() {
      this.previewVisible = false;
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
      this.fileIds.splice(index, 1);
      this.urlArr.splice(index, 1);
      this.isshangchuan = false;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      const formData = new FormData();
      formData.append("file1", file);
      formData.append("fileType", "shopPotentialUser");
      // 上传接口/custAgentActivity/uploadFile.nd
      let config = {
        headers: { "content-type": "multipart/form-data" },
      };
      // 发送请求
      this.Canbutton = true;
      const BASE_API = publicPath;
      axios
        .post(BASE_API + "/comm/method=uploadFile.nd", formData, config)
        .then((res) => {
          if (res.data.message == "上传成功") {
            this.fileIds.push({
              id: res.data.businessId,
            });

            this.urlArr.push(res.data.url);

            if (this.fileIds.length == 1) {
              this.isshangchuan = true;
            }
          } else {
            this.$message.warning("上传失败", res.data.message);
          }
          this.Canbutton = false;
        });
      return false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      this.fileList = fileList;
    },

    checkMap() {
      // if (!this.provinceStr) {
      //     this.$message.info("请选择省份", 2);
      //     return
      // }
      // if (!this.cityStr) {
      //     this.$message.info("请选择城市", 2);
      //     return
      // }
      // if (!this.areaStr) {
      //     this.$message.info("请选择区县", 2);
      //     return
      // }
      // if (!this.streetStr) {
      //     this.$message.info("请选择乡镇街道", 2);
      //     return
      // }
      // this.center = this.provinceStr + this.cityStr + this.areaStr + this.streetStr
      // this.mapShow = true;
    },
    mapReady() {
      this.zoom = 18;
      this.BMap = BMap;
    },
    mapTap(e) {
      this.center = {
        lng: e.Bg.lng,
        lat: e.Bg.lat,
      };
      const myGeo = new this.BMap.Geocoder();
      myGeo.getLocation(
        new this.BMap.Point(this.center.lng, this.center.lat),
        (result) => {
          if (result) {
            // address: "山东省青岛市市南区镇江路101"
            this.formData.address = result.address;
            this.gdCenter = this.bgps_gps(this.center.lng, this.center.lat);
          }
        }
      );
    },

    //百度坐标转高德（传入经度、纬度）
    bgps_gps(bd_lng, bd_lat) {
      var X_PI = (Math.PI * 3000.0) / 180.0;
      var x = bd_lng - 0.0065;
      var y = bd_lat - 0.006;
      var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * X_PI);
      var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * X_PI);
      var gg_lng = z * Math.cos(theta);
      var gg_lat = z * Math.sin(theta);
      return { lng: gg_lng.toFixed(6), lat: gg_lat.toFixed(6) };
    },
    isPostCode(e, v) {
      return /^[0-9]{6}$/.test(Number(v));
    },
    checkAddrDescribe(rule, value, callback) {
      if (value.length <= (this.formData.address&&this.formData.address.length > 44 ? 0 : 45-(this.formData.address&&this.formData.address.length ||0))) {
        callback();
        return;
      }
      callback('地址总字数已超限制,请重新输入');
    },
    //左侧导航点击
    navClickFun(index, isScroll) {
      for (const idx in this.menuList) {
        if (idx == index) {
          if (!isScroll) {
            $("body,html").animate(
              {
                scrollTop: $("#type" + index).offset().top,
              },
              0
            );
          }
          //活动状态
          let timer = setTimeout(() => {
            for (const idx in this.menuList) {
              this.menuList[idx].active = false;
            }
            this.menuList[index].active = true;
            clearTimeout(timer);
          }, 1);
          return;
        }
      }
    },

    //左侧导航跟随滚动
    navScrollFun(index, isScroll) {
      for (const idx in this.menuList) {
        this.menuList[idx].active = false;
      }
      for (const idx in this.menuList) {
        if (idx == index) {
          this.menuList[index].active = true;
          return;
        }
      }
    },
    //判断详情通栏是否吸附
    handleScroll() {
      if (!this.scrollWatch) {
        return;
      }
      if ($("#boxFixedRefer").offset() && $("#boxFixedRefer").offset().top) {
        let scrollTop =
          $("#boxFixedRefer").offset().top - $(window).scrollTop();
        if (scrollTop <= 0) {
          this.isFixed = true;
        } else {
          this.isFixed = false;
        }
      }
      //滚动-》左侧交互
      if ($("#type2").offset().top - $(window).scrollTop() < 40) {
        this.navScrollFun(2, true);
      } else if ($("#type1").offset().top - $(window).scrollTop() < 40) {
        this.navScrollFun(1, true);
      } else if ($("#type0").offset().top - $(window).scrollTop() < 40) {
        this.navScrollFun(0, true);
      }
    },

    //左侧点击交互
    navJumpFun(index) {
      for (const idx in this.menuList) {
        this.menuList[idx].active = false;
      }
      this.menuList[index].active = true;
    },

    provinceChange(e, item) {
      this.provinceStr = this.province[item.data.key].provinceName;
      this.formData.city = "";
      this.formData.area = "";
      this.formData.street = "";
      this.city = [];
      this.area = [];
      this.street = [];
      let data = {
        proviceId: e,
      };
      getCity(data).then((res) => {
        this.city = res.data;
      });
    },
    cityChange(e, item) {
      this.cityStr = this.city[item.data.key].cityName;
      this.formData.area = "";
      this.formData.street = "";
      this.area = [];
      this.street = [];
      let data = {
        cityId: e,
      };
      getArea(data).then((res) => {
        this.area = res.data;
      });
    },
    areaChange(e, item) {
      this.areaStr = this.area[item.data.key].districtName;
      this.formData.street = "";
      this.street = [];
      let data = {
        districtId: e,
      };
      getStreet(data).then((res) => {
        this.street = res.data;
      });
      // 根据区县查询营销中心
      getMarketCenter(e).then((res) => {
        if (res.data.code == 0 && res.data.list[0]) {
          this.marketingCenter = res.data.list[0];
          this.formData.marketingCenter = res.data.list[0].name;
          this.formData.marketingCenterId = res.data.list[0].code;
        }
      });
    },
    streetChange(e, item) {
      this.streetStr = this.street[item.data.key].townName;
    },
    // 返回
    goback() {
      // this.$confirm({
      //   title: "本次修改该尚未保存，是否确认离开",
      //   onOk: () => {
      //     setStoreUser1(1);
      //     this.$router.push("/newMerchantaddEdit");
      //   },
      //   onCancel() {},
      // });
      this.gobacknext=true
    },
    istruegoback() {
      const this_ = this;
      setStoreUser1(1);
      this.MaterialCooperationData.forEach((item) => {
        this.final1.push({
          materialGroupBranchCompany: item.materialGroupBranchCompany
            ? item.materialGroupBranchCompany
            : "", //物料组
          orgId: item.banshichuId ? item.banshichuId : "", //所属办事处id
          terminalOrgId: this_.formData.marketingCenterId ? this_.formData.marketingCenterId : "", //营销中心办事处 todo
          shopLevelId: item.shopLevelId ? item.shopLevelId : "", //门店级别
          managerTel: item.managerTel ? item.managerTel : "", //门店经理电话
          managerName: item.managerName ? item.managerName : "", //门店经理姓名
          shopArea: item.shopArea ? item.shopArea : "", //门店展台面积
          singleMeter: item.singleMeter ? item.singleMeter : "", //展台延米
          annualSales: item.annualSales ? item.annualSales : "", //年销售规模
          forcastSales: item.forcastSales ? item.forcastSales : "", //预计门店规模
          modelCount: item.modelCount ? item.modelCount : "", //样位机个数,//物料组
        });
      });
      let data1 = {
        distributorInfoVO: this.sessionData[0].distributorInfoVO,
        custShopInfoCheckDto: {
          latitude: this.formData.latitude, //经纬度
          longitude: this.formData.longitude, //经纬度
          fullName: this.formData.storeFullName, //门店全称
          searchTerm: this.formData.storeShortName, //门店简称
          shAddress: this.formData.address, //门店地址
          addressDesc: this.formData.addrDescribe, //地址描述
          tradingTypeId: this.formData.circleType, //商圈类型
          bdId: Number(this.formData.bdId),
          bu1Id: Number(this.formData.bu1Id),
          shopExpectedOpeningTime: this.formData.shopExpectedOpeningTime ? moment(this.formData.shopExpectedOpeningTime).format("YYYY-MM-DD") : null,
          areaLeaderId: this.formData.principal, //片区负责人
          shopPropertyId: this.formData.storefront,//"2091883", //店面性质
          // isCoreShop: this.formData.isCore, //是否核心门店
          isCoreShop: 0, //是否核心门店 默认传否
          annualSalesTotal: this.AllMaterialCooperationData.EstimatedStoreSize, //预计门店规模
          managerNameNew: this.AllMaterialCooperationData.StoreManagerName1, //门店经理姓名
          // isSinkChannel: this.formData.isSink, //是否下沉渠道1是，0否
          isSinkChannel:0, //默认否
          managerTelNew: this.AllMaterialCooperationData.StoreManagerTel1, //门店经理电话
          isPlanSpecial: this.formData.isSpecialShop, //是否海信专卖店
          content: this.formData.content, //申请原因
          headFilesStr: this.fileIds[0], //门店照片
          shopInfoDetailCheckList: this.final1, //物料组合作关系
        },
        wareHouseInfoDto:this.sessionData[0].wareHouseInfoDto,
        id: this.sessionData[0].id,
        showSelect: this.sessionData[0].showSelect,
      };
      setStoreUser(data1);
      setzongnian({
        zongnianxs: this.zongnianxs,
        zongyjmd: this.zongyjmd,
      });
      if (this.urlArr.length!=0) {
        setimgurl({
        imgurl: this.urlArr,
      });
      }

      
      setfuzeren({
        fuzeren: this.formData.principalName,
      });
      this.MaterialCooperationData.forEach((i) => {
        this.huancunMaterialCooperationData.push({
          materialGroupBranchCompany1: i.materialGroupBranchCompany1,
          materialGroupBranchCompany: i.materialGroupBranchCompany,

          orgId: i.orgId,
          banshichuId: i.banshichuId,

          yingxiaoid: this_.formData.marketingCenterId ? this_.formData.marketingCenterId : "", //营销中心办事处 //i.yingxiaoid,
          terminalOrgId: this_.formData.marketingCenterId ? this_.formData.marketingCenterId : "", //营销中心办事处 //i.terminalOrgId,

          shopLevel: i.shopLevel,
          shopLevelId: i.shopLevelId,

          managerName: i.managerName,
          managerTel: i.managerTel,
          shopArea: i.shopArea.slice(0, 5),
          singleMeter: i.singleMeter,
          modelCount: i.modelCount,
          annualSales: i.annualSales,
          forcastSales: i.forcastSales,
        });
      });
      setmendian(this.huancunMaterialCooperationData);
      this.$router.push({path:"/newMerchantaddEdit",query:{category:'step'}});
    },
    goback1() {
      this.gobacknext1=true

      // this.$confirm({
      //   title: "本次修改该尚未保存，是否确认离开",
      //   onOk: () => {
      //     this.$router.push("/basicInfo?type=7");
      //   },
      //   onCancel() {},
      // });
    },
    istruegoback1() {
      // this.$router.push("/basicInfo?type=7");
      if(this.$route.path === '/jurisdiction/storeAddEdit'){
        // 管辖的门店
        this.$router.push("/jurisdiction/store");
      }else{
        // 分销的门店
        this.$router.push("/basicInfo?type=7");
      }
    },
    goback2() {
      // this.$router.push("/basicInfo?type=7");
      if(this.$route.path === '/jurisdiction/storeAddEdit'){
        // 管辖的门店
        this.$router.push("/jurisdiction/store");
      }else{
        // 分销的门店
        this.$router.push("/basicInfo?type=7");
      }
    },

    //是否根据输入项进行筛选
    filterOption(input, option) {
      return;
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 选择门店需求同步提报
    storeDemandSelect(idx) {
      this.storeDemandIdx = idx;
    },
    // 添加门店需求同步提报
    addStoreDemand() {
      this.storeDemands.push({
        isNewRow: true,
        orderNo: "", //需求子单号
        wlz: "", //物料组
        bsc: "", //所属办事处
        demandType: "14182400546", //需求类型 写死新店开业
        dispatchType: "0", //派单类型 默认普通安装派单
        supplier: "", //供应商
        costEstimate: "", //费用预估
        time: "", //要求完成时间
        illustrate: "", //说明
        category: [], //品牌
        startYear: "",
        endYear: "",
        netSize: "", //网络规模
      });
    },
    // 删除门店需求同步提报
    delStoreDemand() {
      if (this.storeDemandIdx.length <= 0) {
        this.$message.warning("请至少选择一项数据!");
        return;
      }
      if (this.storeDemandIdx.length > 1) {
        this.$message.warning("只能一条数据进行删除!");
        return;
      }
      let obj = this.storeDemands[this.storeDemandIdx[0]];
      if (obj.isNewRow) {
        this.storeDemands = this.storeDemands.filter(
          (item, index) => !this.storeDemandIdx.includes(index)
        );
        this.storeDemandIdx = [];
      } else {
        this.$message.warning("已提交保存的数据不能删除");
      }
    },
    // 复制门店需求同步提报
    copyStoreDemand() {
      if (this.storeDemandIdx.length <= 0) {
        this.$message.warning("请至少选择一项数据!");
        return;
      }
      if (this.storeDemandIdx.length > 1) {
        this.$message.warning("只能一条数据进行复制!");
        return;
      }
      let obj = this.storeDemands[this.storeDemandIdx[0]];
      this.storeDemands.push(JSON.parse(JSON.stringify(obj)));
    },
    // 办事处列表选择
    bscSelect(idx) {
      this.bscIdx = idx;
    },
    // 添加办事处
    addBsc() {
      if (!this.formData.ownedBusiness) {
        this.$message.info("请选择所属商家", 2);
        return;
      }
      this.bscList.push({
        isNewRow: true,
        bsc: "", //所属办事处
        bscId: "", //所属办事处
        company: "", //分公司名称
        companyId: "", //分公司名称
        category: "", //品类
        wlz: "", //物料组
        storeLevel: "", //门店级别
        operator: "", //所属运营商
        operatorId: "",
        brandPark: "", //是否品牌园
        floor: "", // 所在楼层
        area: "", //门店展厅面积
        num: "", //样机位数量
        manager: "", //门店经理姓名
        managerPhone: "", //门店经理电话
        salesman: "", //营销员姓名/编码
        salesmanPhone: "", //营销员电话
        settlingTime: "", //进驻时间
        freezeTime: "", //冻结时间
        brand: "", //通信品牌信联
        isSap: "", //是否发送SAP
        isEnter: "",
        isTop: "",
        // shippingCompany: '',//发货分公司
        merchant: this.customer.customerName, //补货商家
        merchantId: this.customer.id, //补货商家
        cooperationStatus: "1", //合作状态
        freezeVouchers: "", //冻结凭证
        marketingCenter: "", //营销中心办事处
        file: [],
        // idx: this.bscList.length
        isMainFloor: "", //是否主销楼层
        locationRanking: "", //位置排名
        closedBrand: "", // 紧临品牌
        peopleNum: 0, //计划配置促销员人数
        promoter: "", //促销员
        promoterName: "",
        ztym: "", //展台延米
      });
    },
    // 删除办事处
    delBsc() {
      if (this.bscIdx.length <= 0) {
        this.$message.warning("请选择一项数据!");
        return;
      }
      if (this.bscIdx.length > 1) {
        this.$message.warning("只能一条数据进行删除!");
        return;
      }
      let obj = this.bscList[this.bscIdx[0]];
      if (obj.isNewRow) {
        this.bscList = this.bscList.filter(
          (item, index) => !this.bscIdx.includes(index)
        );
        this.bscIdx = [];
      } else {
        this.$message.warning("已提交保存的数据不能删除");
      }
    },
    // 复制办事处
    copyBsc() {
      if (this.bscIdx.length <= 0) {
        this.$message.warning("请选择一项数据!");
        return;
      }
      if (this.bscIdx.length > 1) {
        this.$message.warning("只能一条数据进行复制!");
        return;
      }
      let obj = this.bscList[this.bscIdx[0]];
      this.bscList.push(JSON.parse(JSON.stringify(obj)));
    },
    // 选择销售规模
    xsgmSelect(idx) {
      this.xsgmIdx = idx;
    },
    // 添加销售规模
    addXsgm() {
      this.xsgmList.push({
        isNewRow: true,
        wlz: "",
        wlzName: "",
        nxsgm: 0,
        yjmdgm: 0,
      });
    },
    // 删除销售规模
    delXsgm() {
      if (this.xsgmIdx.length <= 0) {
        this.$message.warning("请选择一项数据!");
        return;
      }
      if (this.xsgmIdx.length > 1) {
        this.$message.warning("只能一条数据进行删除!");
        return;
      }
      let obj = this.xsgmList[this.xsgmIdx[0]];
      if (obj.isNewRow) {
        this.xsgmList = this.xsgmList.filter(
          (item, index) => !this.xsgmIdx.includes(index)
        );
        this.xsgmIdx = [];
      } else {
        this.$message.warning("已提交保存的数据不能删除");
      }
    },
    // 复制销售规模
    copyXsgm() {
      if (this.xsgmIdx.length <= 0) {
        this.$message.warning("请至少选择一项数据!");
        return;
      }
      if (this.xsgmIdx.length > 1) {
        this.$message.warning("只能一条数据进行复制!");
        return;
      }
      let obj = this.xsgmList[this.xsgmIdx[0]];
      this.xsgmList.push(JSON.parse(JSON.stringify(obj)));
    },
    // 年销售规模输入
    nxsgmInput(n) {},
    getTotal() {
      let znxsgm = 0; //总年销售规模(万元)：
      let zyjmdgm = 0; //总预计门店规模(万元)：
      for (let item of this.xsgmList) {
        // znxsgm += item.nxsgm;
        znxsgm = this.accAdd(znxsgm, item.nxsgm);
        // zyjmdgm += item.yjmdgm;
        zyjmdgm = this.accAdd(zyjmdgm, item.yjmdgm);
      }
      this.formData.znxsgm = znxsgm;
      this.formData.zyjmdgm = zyjmdgm;
    },
    accAdd(arg1, arg2) {
      var r1, r2, m;
      try {
        r1 = arg1.toString().split(".")[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = arg2.toString().split(".")[1].length;
      } catch (e) {
        r2 = 0;
      }
      m = Math.pow(10, Math.max(r1, r2));
      return (arg1 * m + arg2 * m) / m;
    },
    // 获取商家列表
    getMerchant(e) {
      if(this.$route.path === '/jurisdiction/storeAddEdit'){
        // 管辖的门店
        let data = {
          searchstr: e,
        };
        getMerchant2(data).then((res) => {
          if (res.data.code == 0) {
            this.ownedBusiness = res.data.list;
          }
        });
      }else{
        // 分销的门店
        let data = {
          searchstr: e,
        };
        getMerchant(data).then((res) => {
          if (res.data.code == 0) {
            this.ownedBusiness = res.data.list;
          }
        });
      }
      

      
    },
    // 所属商家改变
    ownedBusinessChange(e) {
      this.formData.ownedBusiness = this.ownedBusiness[e].code;
      this.formData.ownedBusinessText = this.ownedBusiness[e].name;
      // 获取营销中心办事处
      this.getMarketingCenterFun(this.formData.ownedBusiness);
      // 获取渠道类别，渠道类别细分，集团一级标签，集团二级标签
      getMerchantDetail(this.formData.ownedBusiness).then((res) => {
        let merchantDetail = res.data.data;
        this.formData.channelCategory = merchantDetail.primaryChannelText;
        this.formData.channelCategoryId = merchantDetail.primaryChannelId;

        this.formData.channelCategorySub = merchantDetail.secondChannelText;
        this.formData.channelCategorySubId = merchantDetail.secondChannelId;

        this.formData.labelLv1 = merchantDetail.primaryLabelText;
        this.formData.labelLv1Id = merchantDetail.primaryLabelId;

        this.formData.labelLv2 = merchantDetail.secondLabelText;
        this.formData.labelLv2Id = merchantDetail.secondLabelId;
      });
      // 存储地址

      // 获取办事处
      getBsc(this.formData.ownedBusiness).then((res) => {
        this.bscInfos = res.data.list;
      });
      this.wlz = [];
      this.marketingCenterBsc = [];
      for (let item of this.bscList) {
        item.bsc = "";
        item.bscId = "";
        item.companyId = "";
        item.company = "";
        item.wlz = "";
        item.marketingCenter = "";
      }
      for (let item of this.xsgmList) {
        item.wlz = "";
      }
      for (let item of this.storeDemands) {
        item.wlz = "";
      }
      if (this.beforedata) {
        this.goGetMatklList();
      }
      this.formData = { ...this.formData };
    },
    principalInput(e) {
      let data = {
        searchstr: e,
      };
      getPrincipal(data).then((res) => {
        if (res.data.code == 0) {
          this.principal = res.data.list;
        }
      });
    },
    // 片区负责人改变
    principalChange(e) {
      this.formData.principal = this.principal[e].code;
      this.formData.principalName = this.principal[e].name;
      getPrincipalDetail(this.formData.principal).then((res) => {
        if (res.data.code == 0) {
          this.formData.principalPhone = res.data.data.loginName;
        }
      });
      this.formData = { ...this.formData };
    },
    // 门店需求物料组改变
    storeDemandWlzChange(idx, item) {
      // item.wlz = 1111;
      item.bsc = this.bscWlz[idx].bscName;
      item.wlz = this.bscWlz[idx].code;
    },
    // 办事处改变
    bscChange(idx, item) {
      item.bsc = this.bscInfos[idx].officeId;
      item.bscName = this.bscInfos[idx].officeName;
      item.bscId = this.bscInfos[idx].officeId;
      item.companyId = this.bscInfos[idx].officeId;
      item.company = this.bscInfos[idx].orgName;
      item.wlz = "";
      item.wlzName = "";
      getWlz(this.customer.id, this.bscInfos[idx].orgId).then((res) => {
        item.wlzList = res.data.list;
        this.$forceUpdate();
      });
      // getMarketingCenter(item.bscId).then((res) => {
      //   this.marketingCenterBsc = res.data.list;
      // });
      for (let item of this.xsgmList) {
        item.wlz = "";
      }
      this.bscWlz = [];
    },
    // 选择所属运营商
    operatorInput(str, item) {
      if (!item.bscId) {
        this.$message.info("请选择所属办事处", 2);
        return;
      }
      if (!item.wlz) {
        this.$message.info("请选择物料组", 2);
        return;
      }
      let data = {
        searchstr: str,
        orgId: item.bscId,
        matklId: item.wlz,
      };
      getOperator(data).then((res) => {
        this.operator = res.data.list;
      });
    },
    operatorChange(id, item) {
      item.operatorId = id;
    },
    salesmanForus(e) {
    },
    salesmanInput(e, opt) {
      if (!opt.bscId) {
        this.$message.info("请选择所属办事处", 2);
        return;
      }
      let data = {
        searchstr: e,
        officeId: opt.bscId,
      };
      getSalesman(data).then((res) => {
        this.salesman = res.data.list;
      });
    },
    // 营销员改变
    salesmanChange(item, idx) {
      item.salesman = this.salesman[idx].code;
      item.salesmanPhone = this.salesman[idx].desc;
    },
    // 促销员输入
    promoterInput(e, opt) {
      if (!opt.bscId) {
        this.$message.info("请选择所属办事处", 2);
        return;
      }
      let data = {
        searchstr: e,
        officeId: opt.bscId,
      };
      getSalesman(data).then((res) => {
        this.promoter = res.data.list;
      });
    },
    // 促销员改变
    promoterChange(item, idx) {
      item.promoter = this.promoter[idx].code;
      item.promoterName = this.promoter[idx].desc;
    },
    // 获取下拉数据
    getParams() {
      return getParams().then((res) => {
        if (res.data.code == 0) {
          let data = res.data.dict;
          this.isCore = data.isCoreShop; //是否核心店铺
          this.isEnter = data.isEnter; //是否入住
          this.cooperationStatus = data.djFlag; //冻结
          this.isSink = data.isSinkChannel; //是否下沉
          this.isSpecialShop = data.isPlanSpecial; //是否海信专卖店
          this.isBooth = data.isSubmitSupplyId; //是否做展台
          this.isTop = data.isTopShop; //是否TOP客户加盟店
          this.marketLevels = data.marketLevelId; //市场级别
          this.storeCategory = data.shopCategory; //门店类别
          this.formData.storeCategory = [data.shopCategory[0].code];
          this.storefront = data.shopPropertyId; //店面性质
          this.brand = data.netStandardId; //通信品端信联
          this.isSap = data.isToSap; //是否发送SAP
          this.demandType = data.supplyTypeId; //需求类型
          this.dispatchType = data.isHeaderUnion; //派单类型
          this.cityLv = data.districtType; //城市级别
          this.category = data.branchId; //品牌
          this.storeLevel = data.shopLevelId; //门店级别
          this.isStandard = data.isUpStandard;
          this.formData.isBooth = "14923253082";
          this.circleType = data.tradingTypeId; //商圈类型
          this.store3Size = data.threeScaleId; //门店三大品类年规模
          this.brandSettled = data.friendBrandId; //友商品牌进驻
          this.isMainFloor = data.isMainFloor; //是否主销楼层
          this.closedBrand = data.closedBrandId; // 紧临品牌
          this.isStandard = data.isUpStandard; //是否达标
        }
      });
    },
    // 供应商输入
    supplierInput(e, item) {
      if (item.dispatchType == 2) {
        let data = {
          searchstr: e,
        };
        getSupplier(data).then((res) => {
          this.supplier = res.data.list;
        });
      }
    },
    supplierChange(value, item) {
      let obj = this.supplier.find((i) => {
        //listData就是上面的数据源
        return i.code === value; //筛选出匹配数据
      });
      item.supplier = obj.code;
      item.supplierName = obj.name;
    },
    // 派单类型改变
    dispatchTypeChange(e, record) {
      this.selctRow = record;
      let obj = this.dispatchType.find((i) => {
        //listData就是上面的数据源
        return i.code == e; //筛选出匹配数据
      });
      record.dispatchType = obj.code;
      if (record.dispatchType == "1") {
        this.msgTips =
          "仅限于总部重点门店建店使用，不会自动派单，将由总部建店负责人在NP-SRM直接派工至总部指定搭建方，请再次确认！";
        this.visible2 = true;
        record.supplier = "";
        record.supplierName = "";
        this.$forceUpdate();
      } else if (record.dispatchType == "2") {
        this.msgTips =
          "仅针对连锁渠道指定商家（如：顶、地制作），请再次确认是否要进行连锁统装派单？如选择此项，后期将纳入审计重点核查门店！";
        this.visible2 = true;
        this.$forceUpdate();
      } else {
        record.supplier = "";
        record.supplierName = "";
        record.disabled = true;
        this.visible2 = false;
        this.$forceUpdate();
      }
    },
    jlname() {
      if (this.AllMaterialCooperationData.StoreManagerName) {
        this.jlnamespan = true;
      } else {
        this.jlnamespan = false;
      }
    },
    // 点击确定弹窗删除
    handleOk() {
      if (this.selctRow.dispatchType == 2) {
        this.$forceUpdate();
      } else {
        this.selctRow.supplier = "";
        this.selctRow.supplierName = "";
        this.$forceUpdate();
      }
      this.visible2 = false;
    },
    // 点击取消 把当前的 需求类型置空
    handleCancel33() {
      this.visible2 = false;
      this.selctRow.dispatchType = "0";
      // this.selctRow.isHeaderUnionText = '普通安装派单'
      this.selctRow.supplier = "";
      this.selctRow.supplierName = "";
      this.$forceUpdate();
    },

    storeShortNameSearch(e) {
      let data = {
        searchstr: e,
        type: 2,
      };
      getStore(data).then((res) => {
        if (res.data.code == 0) {
          this.storeShortNames = res.data.list;
        }
      });
    },

    addline() {
      if(this.isShow){
        return
      }
      //物料合作关系
      this.isShow = true;
      // this.AllMaterialCooperationData = {};
      this.AllMaterialCooperationData.custShopInfoDetailId = null;
      this.AllMaterialCooperationData.MaterialGroup = "";
      this.AllMaterialCooperationData.MaterialGroupcode = "";
      this.AllMaterialCooperationData.Office = "";
      // this.AllMaterialCooperationData.MarketingCenterOffice = "";
      // this.MarketingCenterOfficeid = "";
      this.AllMaterialCooperationData.StoreLevel = "";
      this.AllMaterialCooperationData.StoreManagerTel = "";
      this.AllMaterialCooperationData.StoreManagerName = "";
      this.AllMaterialCooperationData.StoreBoothArea = "";
      this.AllMaterialCooperationData.Boothlinearmeter = "";
      this.AllMaterialCooperationData.AnnualSalesScale = "";
      this.AllMaterialCooperationData.EstimatedStoreSize = "";
      this.AllMaterialCooperationData.NumberOfPrototypeBits = "";
      this.AllMaterialCooperationData.StoreManagerTel = this.AllMaterialCooperationData.StoreManagerTel1;
      this.AllMaterialCooperationData.StoreManagerName = this.AllMaterialCooperationData.StoreManagerName1;
      // this.queryOffice()
    },
   async wuliaochange() {
      if (this.beforedata && this.Materialgroupdropdown && this.Materialgroupdropdown.length > 0) {
        // 单独建店
        let param = this.Materialgroupdropdown.find(
          (i) => i.fullName == this.AllMaterialCooperationData.MaterialGroup
        );
        // this.marketObj = param
        //获取办事处
        getBscList(param.fwOrgId).then((res) => {
          this.banshichu = res.data.list;
          // 判断选中的办事处 和新的办事处的id 是否能匹配上 不能的话 办事处和营销中心办事处置空
          if(this.AllMaterialCooperationData.Office) {
            let index = this.banshichu.findIndex((item) => {
              return item.name == this.AllMaterialCooperationData.Office;
            })
            if(index<0) {
              this.AllMaterialCooperationData.Office = ''
              // this.getMarketingCenterOffice = [];
              // this.AllMaterialCooperationData.MarketingCenterOffice=''
            }
          }
        });
        
        return;
      }
      if (this.AllMaterialCooperationData.MaterialGroup && this.sessionData && this.sessionData.length > 0 && this.sessionData[0].distributorInfoVO && this.sessionData[0].distributorInfoVO.tradeRelationVOList && this.sessionData[0].distributorInfoVO.tradeRelationVOList.length > 0) {
        this.AllMaterialCooperationData.MaterialGroupcode = this.sessionData[0].distributorInfoVO.tradeRelationVOList[
          this.sessionData[0].distributorInfoVO.tradeRelationVOList.findIndex(
            (item) => {
              return (
                item.materialGroupBranchCompany2 ==
                this.AllMaterialCooperationData.MaterialGroup
              );
            }
          )
        ].materialGroupBranchCompany;
        var data1 = this.AllMaterialCooperationData.MaterialGroupcode.slice(12);

        //获取办事处
        getBscList(data1).then((res) => {
          this.banshichu = res.data.list;
        });
      }
    },
    // 改变所属办事处获取数据
    banshichange() {
      if (this.AllMaterialCooperationData.Office) {
        
        this.banshichuId = this.banshichu[
          this.banshichu.findIndex((item) => {
            return item.name == this.AllMaterialCooperationData.Office;
          })
        ].code;
        // 24978于淼需求 去掉联动 // 获取营销中心办事处
        // queryOffice(this.banshichuId).then((res) => {
        //   this.getMarketingCenterOffice = res.data.list;
        //   this.AllMaterialCooperationData.MarketingCenterOffice=res.data.list[0].name
        // });
      }
    },
    // 获取营销中心办事处
    getMarketingCenterFun(custId){
      this.formData.marketingCenterId = '';
      this.getMarketingCenterOffice = [];
      getMarketingCenter2(custId).then((res) => {
        if(res.data.code == 0){
          this.getMarketingCenterOffice = res.data.list;
        }
      });
    },
    // 一步建店获取营销中心办事处
    getMarketingCenterFun2(materialGroupBranchCompany){
      this.formData.marketingCenterId = '';
      this.getMarketingCenterOffice = [];
      getMarketingCenter3(materialGroupBranchCompany).then((res) => {
        if(res.data.code == 0){
          this.getMarketingCenterOffice = res.data.list;
        }
      });
    },
    
    banshichuchange() {
      if(this.Materialgroupdropdown && this.Materialgroupdropdown.length > 0){
        let param = this.Materialgroupdropdown.find(
            (i) => i.fullName == this.AllMaterialCooperationData.MaterialGroup
        );

          // this.marketObj = param
          // debugger
          //获取办事处
        if (param) {
            getBscList(param.fwOrgId).then((res) => {
            this.banshichu = res.data.list;
          });
        }
          return;
      }
      
    },
    // 24978于淼需求 去掉联动 
    // yingxiaochange() {
    //   if (this.AllMaterialCooperationData.Office) {

    //     this.banshichuId = this.banshichu[
    //       this.banshichu.findIndex((item) => {
    //         return item.name == this.AllMaterialCooperationData.Office;
    //       })
    //     ].code;
    //     queryOffice(this.banshichuId).then((res) => {
    //       this.getMarketingCenterOffice = res.data.list;
    //     });
    //   }
    // },

    // 点击取消隐藏贸易信息
    deletemessage() {
       this.AllMaterialCooperationData.MaterialGroup = "";
        this.AllMaterialCooperationData.MaterialGroupcode = "";
        this.AllMaterialCooperationData.Office = "";
        this.banshichuId = "";
        // this.AllMaterialCooperationData.MarketingCenterOffice = "";
        // this.MarketingCenterOfficeid = "";
        this.AllMaterialCooperationData.StoreLevel = "";
        this.StorelevelId = "";
        this.AllMaterialCooperationData.StoreManagerTel = "";
        this.AllMaterialCooperationData.StoreManagerName = "";
        this.AllMaterialCooperationData.StoreBoothArea = "";
        this.AllMaterialCooperationData.Boothlinearmeter = "";
        this.AllMaterialCooperationData.AnnualSalesScale = "";
        this.AllMaterialCooperationData.EstimatedStoreSize = "";
        this.AllMaterialCooperationData.NumberOfPrototypeBits = "";
        this.isEdit = false;
        this.isShow = false;
    },

    //添加w物料合作关系数据
    IncreaseTradeData1() {
      //查看所选物料组跟已经添加物料组是否一样
      let p = this.MaterialCooperationData.some((item) => {
        return (
          item.materialGroupBranchCompany1.split('-')[0]==
          this.AllMaterialCooperationData.MaterialGroup.split('-')[0]
        );
      });

      if (!this.isEdit && p) {
        this.$message.warning("该物料组已维护！");
        return false;
      }
      if ((!Number(this.AllMaterialCooperationData.StoreBoothArea)&&Number(this.AllMaterialCooperationData.StoreBoothArea)!=0)||!/^\+?(0|[1-9][0-9]*)$/.test(this.AllMaterialCooperationData.StoreBoothArea)) {
         this.$message.warning("请输入正确的门店展台面积");
        return;
      }
      if ((!Number(this.AllMaterialCooperationData.Boothlinearmeter)&&Number(this.AllMaterialCooperationData.Boothlinearmeter)!=0)||!/^\+?(0|[1-9][0-9]*)$/.test(this.AllMaterialCooperationData.Boothlinearmeter)) {
         this.$message.warning("请输入正确的展台延米");
        return;
      }
      if ((!Number(this.AllMaterialCooperationData.AnnualSalesScale)&&Number(this.AllMaterialCooperationData.AnnualSalesScale)!=0)||!/^\+?(0|[1-9][0-9]*)$/.test(this.AllMaterialCooperationData.AnnualSalesScale)) {
         this.$message.warning("请输入正确的年销售规模");
        return;
      }
      if ((!Number(this.AllMaterialCooperationData.EstimatedStoreSize)&&Number(this.AllMaterialCooperationData.EstimatedStoreSize)!=0)||!/^\+?(0|[1-9][0-9]*)$/.test(this.AllMaterialCooperationData.EstimatedStoreSize)) {
         this.$message.warning("请输入正确的预计门店规模");
        return;
      }
      if(!this.AllMaterialCooperationData.NumberOfPrototypeBits){
        this.$message.warning("请输入样位机个数");
        return;
      }
      // 判断是不是有没有填写的值
      // var arr = Object.values(this.AllMaterialCooperationData).filter(
      //   (item) => {
      //     if (item !== "") {
      //       return true; //过滤掉为空的项
      //     }
      //   }
      // );
      if(this.type=="add"){
        // if (arr.length < 11 && this.AllMaterialCooperationData.MarketingCenterOffice) {
        //   this.$message.warning("请补全物料合作关系中的内容");
        //   return false;
        // } else if (arr.length < 11 && !this.AllMaterialCooperationData.MarketingCenterOffice) {
        //    this.$message.warning("请补全物料合作关系中的内容");
        //   return false;
        // }
        var phoneReg = /^1[0-9]{10}$/;
        if (!phoneReg.test(this.AllMaterialCooperationData.StoreManagerTel)) {
          this.$message.warning("请输入正确的门店经理电话");
          return;
        }
      }
      // if (arr.length < 11 && this.AllMaterialCooperationData.MarketingCenterOffice) {
      //   this.$message.warning("请补全物料合作关系中的内容");
      //   return false;
      // } else if (arr.length < 11 && !this.AllMaterialCooperationData.MarketingCenterOffice) {
      //    this.$message.warning("请补全物料合作关系中的内容");
      //   return false;
      // }
      var phoneReg = /^1[0-9]{10}$/;
      if (!phoneReg.test(this.AllMaterialCooperationData.StoreManagerTel)) {
        this.$message.warning("请输入正确的门店经理电话");
        return;
      }
      
      //获取办事处id
      if(this.banshichu && this.banshichu.length > 0){
        this.banshichuId = this.banshichu[
          this.banshichu.findIndex((item) => {
            return item.name == this.AllMaterialCooperationData.Office;
          })
        ].code;
      }
      
      //获取门店级别id
      if (this.isEdit) {
        if (this.type == "add") {
          this.StorelevelId = this.storeLevel[
            this.storeLevel.findIndex((item) => {
              return (
                item.name ==
                this.AllMaterialCooperationData.StoreLevel
              );
            })
          ].code;
        } else {
          if(this.storeLevel.some(j=>j.name.slice(0, 5) == this.AllMaterialCooperationData.StoreLevel)){
            this.StorelevelId = this.storeLevel.filter(j=>j.name.slice(0, 5) == this.AllMaterialCooperationData.StoreLevel)[0].code;
          }
          //  this.StorelevelId = this.storeLevel[
          //   this.storeLevel.findIndex((item) => {
          //     return (
          //       item.name.slice(0, 5) ==
          //       this.AllMaterialCooperationData.StoreLevel
          //     );
          //   })
          // ].code;
          // this.StorelevelId = this.storeLevel.filter(j=>j.name.slice(0, 5) == this.AllMaterialCooperationData.StoreLevel)[0].code;
        }
         
      } else {
          this.StorelevelId = this.storeLevel[
            this.storeLevel.findIndex((item) => {
              return (
                item.name ==
                this.AllMaterialCooperationData.StoreLevel
              );
            })
          ].code;
      }
    
      
      //获取物料组id
      if (this.affterdata) {
        this.AllMaterialCooperationData.MaterialGroupcode = this.sessionData[0].distributorInfoVO.tradeRelationVOList[
          this.sessionData[0].distributorInfoVO.tradeRelationVOList.findIndex(
            (item) => {
              return (
                item.materialGroupBranchCompany2 ==
                this.AllMaterialCooperationData.MaterialGroup
              );
            }
          )
        ].materialGroupBranchCompany;
      }

    //   if (this.AllMaterialCooperationData.MarketingCenterOffice) {
    //     //获取营销中心办事处id
    //   this.MarketingCenterOfficeid = this.getMarketingCenterOffice[
    //     this.getMarketingCenterOffice.findIndex((item) => {
    //       return (item.name == this.AllMaterialCooperationData.MarketingCenterOffice);
    //     })
    //   ].code;
    //  }

      //点击编辑之后再添加

      if (this.isEdit) {
        this.materialObj = this.Materialgroupdropdown.find(
          (i) => i.fullName == this.AllMaterialCooperationData.MaterialGroup
        );
        var newarr = []
        newarr = this.MaterialCooperationData.filter(i => {
          return i.materialGroupBranchCompany1!=this.MaterialCooperationData[this.EditIndex].materialGroupBranchCompany1
        })
         let p1 = newarr.some((item) => {
        return (
          item.materialGroupBranchCompany1.split('-')[0]==
          this.AllMaterialCooperationData.MaterialGroup.split('-')[0]
        );
         });
        if (p1) {
           this.$message.warning("该物料组已维护！");
           return false;
        }
        
        this.MaterialCooperationData.splice(this.EditIndex, 1, {
          custShopInfoDetailId: this.AllMaterialCooperationData.custShopInfoDetailId || null, // 生效id
          materialGroupBranchCompany1: this.AllMaterialCooperationData
            .MaterialGroup, //物料组
          materialGroupBranchCompany: this.AllMaterialCooperationData
            .MaterialGroupcode, //物料组id
          orgId: this.AllMaterialCooperationData.Office, //所属办事处
          banshichuId: this.banshichuId, //所属办事处id
          terminalOrgId: this.formData.marketingCenterId ? this.formData.marketingCenterId : "", // this.AllMaterialCooperationData.MarketingCenterOffice, //营销中心办事处 todo
          yingxiaoid: this.formData.marketingCenterId ? this.formData.marketingCenterId : "", //this.MarketingCenterOfficeid, //营销中心办事处id
          shopLevel: this.AllMaterialCooperationData.StoreLevel, //门店级别
          shopLevelId: this.StorelevelId, //门店级别id
          managerTel: this.AllMaterialCooperationData.StoreManagerTel, //门店经理电话
          managerName: this.AllMaterialCooperationData.StoreManagerName, //门店经理姓名
          shopArea: this.AllMaterialCooperationData.StoreBoothArea, //门店展台面积
          singleMeter: this.AllMaterialCooperationData.Boothlinearmeter, //展台延米
          annualSales: this.AllMaterialCooperationData.AnnualSalesScale, //年销售规模
          forcastSales: this.AllMaterialCooperationData.EstimatedStoreSize, //预计门店规模
          modelCount: this.AllMaterialCooperationData.NumberOfPrototypeBits, //样位机个数
          materialObj: this.materialObj?this.materialObj:'',
          id:this.MaterialCooperationData[this.EditIndex].id
        }); 

        this.AllMaterialCooperationData.MaterialGroup = "";
        this.AllMaterialCooperationData.MaterialGroupcode = "";
        this.AllMaterialCooperationData.Office = "";
        this.banshichuId = "";
        this.AllMaterialCooperationData.MarketingCenterOffice = "";
        this.MarketingCenterOfficeid = "";
        this.AllMaterialCooperationData.StoreLevel = "";
        this.StorelevelId = "";
        this.AllMaterialCooperationData.StoreManagerTel = "";
        this.AllMaterialCooperationData.StoreManagerName = "";
        this.AllMaterialCooperationData.StoreBoothArea = "";
        this.AllMaterialCooperationData.Boothlinearmeter = "";
        this.AllMaterialCooperationData.AnnualSalesScale = "";
        this.AllMaterialCooperationData.EstimatedStoreSize = "";
        this.AllMaterialCooperationData.NumberOfPrototypeBits = "";
        this.isEdit = false;
        this.isShow = false;
        return false;
      } else {
        if (p) {
          this.$message.warning("该物料组-分公司已维护！");
          return false;
        } else {
          // 单独建店
          this.materialObj = this.Materialgroupdropdown.find(
            (i) => i.fullName == this.AllMaterialCooperationData.MaterialGroup
          );
         
          this.MaterialCooperationData.push({
            materialGroupBranchCompany1: this.AllMaterialCooperationData
              .MaterialGroup, //物料组
            materialGroupBranchCompany: this.AllMaterialCooperationData
              .MaterialGroupcode, //物料组id
            orgId: this.AllMaterialCooperationData.Office, //所属办事处
            banshichuId: this.banshichuId, //所属办事处id
            terminalOrgId: this.formData.marketingCenterId ? this.formData.marketingCenterId : "",//this.AllMaterialCooperationData.MarketingCenterOffice, //营销中心办事处
            yingxiaoid: this.formData.marketingCenterId ? this.formData.marketingCenterId : "",//this.MarketingCenterOfficeid, //营销中心办事处id todo
            shopLevel: this.AllMaterialCooperationData.StoreLevel, //门店级别
            shopLevelId: this.StorelevelId, //门店级别id
            managerTel: this.AllMaterialCooperationData.StoreManagerTel, //门店经理电话
            managerName: this.AllMaterialCooperationData.StoreManagerName, //门店经理姓名
            shopArea: this.AllMaterialCooperationData.StoreBoothArea, //门店展台面积
            singleMeter: this.AllMaterialCooperationData.Boothlinearmeter, //展台延米
            annualSales: this.AllMaterialCooperationData.AnnualSalesScale, //年销售规模
            forcastSales: this.AllMaterialCooperationData.EstimatedStoreSize, //预计门店规模
            modelCount: this.AllMaterialCooperationData.NumberOfPrototypeBits, //样位机个数
            materialObj: this.materialObj,
          });
          if(this.type!="add"){
          this.AllMaterialCooperationData.MaterialGroup = "";
          this.AllMaterialCooperationData.MaterialGroupcode = "";
          this.AllMaterialCooperationData.Office = "";
          this.banshichuId = "";
          this.AllMaterialCooperationData.MarketingCenterOffice = "";
          this.MarketingCenterOfficeid = "";
          this.AllMaterialCooperationData.StoreLevel = "";
          this.StorelevelId = "";
          this.AllMaterialCooperationData.StoreManagerTel = "";
          this.AllMaterialCooperationData.StoreManagerName = "";
          this.AllMaterialCooperationData.StoreBoothArea = "";
          this.AllMaterialCooperationData.Boothlinearmeter = "";
          this.AllMaterialCooperationData.AnnualSalesScale = "";
          this.AllMaterialCooperationData.EstimatedStoreSize = "";
          this.AllMaterialCooperationData.NumberOfPrototypeBits = "";
          }
        }
      }
      //总年销售规模
      this.zongnianxs = this.MaterialCooperationData.reduce((v, i) => {
        return (v += Number(i.annualSales));
      }, 0);
      //总预计门店规模
      this.zongyjmd = this.MaterialCooperationData.reduce((v, i) => {
        return (v += Number(i.forcastSales));
      }, 0);

      this.isShow = false;
    },
    //点击删除
    MaterialCooperationDel(index) {
      this.isLoginModalShow = true;
      this.getindex = index;
    },
    userLayout() {
      var index = this.getindex;
      this.MaterialCooperationData.splice(index, 1);
    },
    //点击编辑
    TradeEditor(index) {
      this.getindex=index
      this.isShow = true;
      this.isEdit = true;
      this.AllMaterialCooperationData.custShopInfoDetailId = this.MaterialCooperationData[
        index
      ].custShopInfoDetailId || null; // 生效id
      this.AllMaterialCooperationData.MaterialGroup = this.MaterialCooperationData[
        index
      ].materialGroupBranchCompany1; //物料组
      this.AllMaterialCooperationData.MaterialGroupcode = this.MaterialCooperationData[
        index
      ].materialGroupBranchCompany; //物料组id      
      this.AllMaterialCooperationData.Office = this.MaterialCooperationData[
        index
      ].orgId; //办事处
      this.wuliaochange()
      // this.banshichu.push({
      //   name: this.MaterialCooperationData[index].orgId,
      //   code: this.MaterialCooperationData[index].banshichuId,
      // }); //办事处
      this.banshichuId = this.MaterialCooperationData[index].banshichuId;
      // this.AllMaterialCooperationData.MarketingCenterOffice = this.MaterialCooperationData[
      //   index
      // ].terminalOrgId; //营销中心办事处
      // this.MarketingCenterOfficeid = this.MaterialCooperationData[
      //   index
      // ].yingxiaoid; //营销中心办事处id
      // this.getMarketingCenterOffice.push({
      //   name: this.MaterialCooperationData[index].terminalOrgId,
      //   code: this.MaterialCooperationData[index].yingxiaoid,
      // });
      this.AllMaterialCooperationData.StoreLevel = this.MaterialCooperationData[
        index
      ].shopLevel; //门店级别
      this.StorelevelId = this.MaterialCooperationData[index].shopLevelId; //门店级别id
      this.AllMaterialCooperationData.StoreManagerTel = this.MaterialCooperationData[
        index
      ].managerTel; //门店经理电话
      this.AllMaterialCooperationData.StoreManagerName = this.MaterialCooperationData[
        index
      ].managerName; //门店经理姓名
      this.AllMaterialCooperationData.StoreBoothArea = this.MaterialCooperationData[
        index
      ].shopArea; //门店展台面积
      this.AllMaterialCooperationData.Boothlinearmeter = this.MaterialCooperationData[
        index
      ].singleMeter; //展台延米
      this.AllMaterialCooperationData.AnnualSalesScale = this.MaterialCooperationData[
        index
      ].annualSales; //年销售规模
      this.AllMaterialCooperationData.EstimatedStoreSize = this.MaterialCooperationData[
        index
      ].forcastSales; //预计门店规模
      this.AllMaterialCooperationData.NumberOfPrototypeBits = this.MaterialCooperationData[
        index
      ].modelCount; //样位机个数
      
      this.EditIndex = index;
    },

    //点击下一步按钮
    savedata() {
      const this_ = this;
      if (!this.formData.storeFullName) {
        this.$message.warning("请输入门店全称");
        return;
      }
      var fullnamedata = {
        "shopFullName":this.formData.storeFullName
      }

      if (!this.formData.storeShortName) {
        this.$message.warning("请输入门店简称");
        return;
      }
        if (this.formData.storeShortName.length>10) {
        this.$message.warning("简称大于10个字");
        return;
      }
      if (!this.formData.circleType) {
        this.$message.warning("请选择商圈类型");
        return;
      }
      if (!this.formData.shopExpectedOpeningTime) {
        this.$message.warning("请选择预计开业时间");
        return;
      }
      if (!this.formData.bdId) {
        this.$message.warning("请选择BD");
        return;
      }
      if (!this.formData.bu1Id) {
        this.$message.warning("请选择BU");
        return;
      }
      if(!this.formData.principal){
        this.$message.warning("请选择片区负责人");
        return;
      }
      // if (!this.formData.isCore) {
      //   this.$message.warning("请选择是否核心门店");
      //   return;
      // }

      // if (!this.formData.isSink) {
      //   this.$message.warning("请选择是否下沉渠道");
      //   return;
      // }
      if(!this.AllMaterialCooperationData.StoreManagerName1){
        this.$message.warning("请输入门店经理姓名");
        return;
      }
      if (!this.formData.isSpecialShop) {
        this.$message.warning("请选择是否海信专卖店");
        return;
      }
      if(!this.AllMaterialCooperationData.StoreManagerTel1){
        this.$message.warning("请输入门店经理电话");
        return;
      }
      var phoneReg = /^1[0-9]{10}$/;
      if (!phoneReg.test(this.AllMaterialCooperationData.StoreManagerTel1)) {
        this.$message.warning("请输入正确的商家经理手机号");
        return;
      }

      if(!this.formData.marketingCenterId){
        this.$message.warning("请选择营销中心办事处");
        return;
      }
      if (!this.formData.content) {
        this.$message.warning("请输入申请原因");
        return;
      }
      if(!this.fileIds[0]){
        this.$message.warning("请上传门店图片");
        return;
      }

      if (this.MaterialCooperationData.length == 0) {
        this.$message.warning("请添加物料合作关系数据");
        return false;
      }
      setTimeout(() => {
        getfullname(fullnamedata).then((res) => {
          this.isfullname = res.data.data
          if (this.isfullname) {
            this.$message.warning("门店全称已存在");
           return false;
         }
        })
      }, 100);
      setTimeout(() => {
        this.MaterialCooperationData.forEach((item) => {
          this.final1.push({
            materialGroupBranchCompany: item.materialGroupBranchCompany
              ? item.materialGroupBranchCompany
              : "", //物料组
            orgId: item.banshichuId ? item.banshichuId : "", //所属办事处id
            terminalOrgId: this_.formData.marketingCenterId ? this_.formData.marketingCenterId : "",//item.yingxiaoid ? item.yingxiaoid : "", //营销中心办事处
            shopLevelId: item.shopLevelId ? item.shopLevelId : "", //门店级别
            managerTel: item.managerTel ? item.managerTel : "", //门店经理电话
            managerName: item.managerName ? item.managerName : "", //门店经理姓名
            shopArea: item.shopArea ? item.shopArea : "", //门店展台面积
            singleMeter: item.singleMeter ? item.singleMeter : "", //展台延米
            annualSales: item.annualSales ? item.annualSales : "", //年销售规模
            forcastSales: item.forcastSales ? item.forcastSales : "", //预计门店规模
            modelCount: item.modelCount ? item.modelCount : "", //样位机个数,//物料组
          });
        });
        let data1 = {
          distributorInfoVO: this.sessionData[0].distributorInfoVO,
          custShopInfoCheckDto: {
            latitude: this.formData.latitude, //经纬度
            longitude: this.formData.longitude, //经纬度
            fullName: this.formData.storeFullName, //门店全称
            searchTerm: this.formData.storeShortName, //门店简称
            shAddress: this.formData.address, //门店地址
            addressDesc: this.formData.addrDescribe, //地址描述
            tradingTypeId: this.formData.circleType, //商圈类型
            shopExpectedOpeningTime: this.formData.shopExpectedOpeningTime ? moment(this.formData.shopExpectedOpeningTime).format("YYYY-MM-DD") : null,
            bdId: Number(this.formData.bdId),
            bu1Id: Number(this.formData.bu1Id),
            areaLeaderId: this.formData.principal, //片区负责人
            shopPropertyId: this.formData.storefront,//"2091883", //店面性质
            // isCoreShop: this.formData.isCore, //是否核心门店
            isCoreShop: 0, //是否核心门店 修改为默认传否
            annualSalesTotal: this.AllMaterialCooperationData.EstimatedStoreSize, //预计门店规模
            managerNameNew: this.AllMaterialCooperationData.StoreManagerName1, //门店经理姓名
            // isSinkChannel: this.formData.isSink, //是否下沉渠道1是，0否
            isSinkChannel:0, //默认否
            managerTelNew: this.AllMaterialCooperationData.StoreManagerTel1, //门店经理电话
            isPlanSpecial: this.formData.isSpecialShop, //是否海信专卖店
            content: this.formData.content, //申请原因
            headFilesStr: this.fileIds[0], //门店照片
            shopInfoDetailCheckList: this.final1, //物料组合作关系
          },
          id: this.sessionData[0].id,
          showSelect: this.sessionData[0].showSelect,
        };
        stepBuildStoreParamCheck(data1).then((res) => {
          if(res.data.code ==1){
            this.final1=[]
            this.$message.warning(res.data.msg)
            return false
          }
          if(res.data.code!=0){
            this.final1=[]
            return false
          }else{
            this.showModal();
            this.final1=[]
          }
        })
      }, 100);
    },
    //点击下一步成功之后的弹窗
    showModal() {
      this.visiblecangku = true;
    },
    // showModal1() {
    //     this.visibledata = true
    // },
    // 点击建仓弹窗的确定
    hideModal() {
      const this_ = this;
      this.MaterialCooperationData.forEach((item) => {
        this.final1.push({
          materialGroupBranchCompany: item.materialGroupBranchCompany
            ? item.materialGroupBranchCompany
            : "", //物料组
          orgId: item.banshichuId ? item.banshichuId : "", //所属办事处id
          terminalOrgId: this_.formData.marketingCenterId ? this_.formData.marketingCenterId : "",//item.yingxiaoid ? item.yingxiaoid : "", //营销中心办事处 todo
          shopLevelId: item.shopLevelId ? item.shopLevelId : "", //门店级别
          managerTel: item.managerTel ? item.managerTel : "", //门店经理电话
          managerName: item.managerName ? item.managerName : "", //门店经理姓名
          shopArea: item.shopArea ? item.shopArea : "", //门店展台面积
          singleMeter: item.singleMeter ? item.singleMeter : "", //展台延米
          annualSales: item.annualSales ? item.annualSales : "", //年销售规模
          forcastSales: item.forcastSales ? item.forcastSales : "", //预计门店规模
          modelCount: item.modelCount ? item.modelCount : "", //样位机个数,//物料组
        });
      });
      let data1 = {
        distributorInfoVO: this.sessionData[0].distributorInfoVO,
        custShopInfoCheckDto: {
          latitude: this.formData.latitude, //经纬度
          longitude: this.formData.longitude, //经纬度
          fullName: this.formData.storeFullName, //门店全称
          searchTerm: this.formData.storeShortName, //门店简称
          shAddress: this.formData.address, //门店地址
          addressDesc: this.formData.addrDescribe, //地址描述
          tradingTypeId: this.formData.circleType, //商圈类型
          shopExpectedOpeningTime: this.formData.shopExpectedOpeningTime ? moment(this.formData.shopExpectedOpeningTime).format("YYYY-MM-DD") : null,
          bdId: Number(this.formData.bdId),
          bu1Id: Number(this.formData.bu1Id),
          areaLeaderId: this.formData.principal, //片区负责人
          shopPropertyId: this.formData.storefront,//"2091883", //店面性质
          // isCoreShop: this.formData.isCore, //是否核心门店
          isCoreShop: 0, //是否核心门店 默认传否
          annualSalesTotal: this.AllMaterialCooperationData.EstimatedStoreSize, //预计门店规模
          managerNameNew: this.AllMaterialCooperationData.StoreManagerName1, //门店经理姓名
          // isSinkChannel: this.formData.isSink, //是否下沉渠道1是，0否
          isSinkChannel:0, //默认否
          managerTelNew: this.AllMaterialCooperationData.StoreManagerTel1, //门店经理电话
          isPlanSpecial: this.formData.isSpecialShop, //是否海信专卖店
          content: this.formData.content, //申请原因
          headFilesStr: this.fileIds[0], //门店照片
          shopInfoDetailCheckList: this.final1, //物料组合作关系
        },
        wareHouseInfoDto:this.sessionData[0]?this.sessionData[0]?.wareHouseInfoDto:"",
        id: this.sessionData[0].id,
        showSelect: this.sessionData[0].showSelect,
      };
      setStoreUser(data1);
      setzongnian({
        zongnianxs: this.zongnianxs,
        zongyjmd: this.zongyjmd,
      });
      if (this.urlArr.length!=0) {
        setimgurl({
        imgurl: this.urlArr,
      });
      }

      
      setfuzeren({
        fuzeren: this.formData.principalName,
      });
      this.MaterialCooperationData.forEach((i) => {
        this.huancunMaterialCooperationData.push({
          materialGroupBranchCompany1: i.materialGroupBranchCompany1,
          materialGroupBranchCompany: i.materialGroupBranchCompany,

          orgId: i.orgId,
          banshichuId: i.banshichuId,

          yingxiaoid: this_.formData.marketingCenterId ? this_.formData.marketingCenterId : "",//i.yingxiaoid, // todo
          terminalOrgId: this_.formData.marketingCenterId ? this_.formData.marketingCenterId : "",//i.terminalOrgId,

          shopLevel: i.shopLevel,
          shopLevelId: i.shopLevelId,

          managerName: i.managerName,
          managerTel: i.managerTel,
          shopArea: i.shopArea.slice(0, 5),
          singleMeter: i.singleMeter,
          modelCount: i.modelCount,
          annualSales: i.annualSales,
          forcastSales: i.forcastSales,
        });
      });
      setmendian(this.huancunMaterialCooperationData);

      setStoreUser1(4);
      this.visiblecangku = false;
      //  save(data1).then(res => {
      //             this.spinning = false;
      //             if (res.data.code == 0) {

      //                 setTimeout(() => {
      //                     this.$router.push('/distributorWarehouse/addDistributorWarehouse')
      //                 }, 2000);
      //             } else {
      //                 this.$message.info(res.data.msg, 2);
      //             }
      //         })
      this.$router.push({
        path: "/distributorWarehouse/addDistributorWarehouse",
        query: { category: "step" },
      });
    },
    // 点击建仓弹窗的取消
    hideModal1() {
      this.visiblecangku = false;
      this.visibledata = true;
    },
    // 点击提交弹窗的确定
    hideModal2() {
      const this_ = this;
      const hide = this.$message.loading("正在提交请稍等..", 0);
      setTimeout(hide, 3000);
      this.tijiaoable = true;

      this.MaterialCooperationData.forEach((item) => {
        this.final1.push({
          materialGroupBranchCompany: item.materialGroupBranchCompany
            ? item.materialGroupBranchCompany
            : "", //物料组
          orgId: item.banshichuId ? item.banshichuId : "", //所属办事处id
          terminalOrgId: this_.formData.marketingCenterId ? this_.formData.marketingCenterId : "",//item.yingxiaoid ? item.yingxiaoid : "", //营销中心办事处 / todo
          shopLevelId: item.shopLevelId ? item.shopLevelId : "", //门店级别
          managerTel: item.managerTel ? item.managerTel : "", //门店经理电话
          managerName: item.managerName ? item.managerName : "", //门店经理姓名
          shopArea: item.shopArea ? item.shopArea : "", //门店展台面积
          singleMeter: item.singleMeter ? item.singleMeter : "", //展台延米
          annualSales: item.annualSales ? item.annualSales : "", //年销售规模
          forcastSales: item.forcastSales ? item.forcastSales : "", //预计门店规模
          modelCount: item.modelCount ? item.modelCount : "", //样位机个数,//物料组
        });
      });
      let data1 = {
        distributorInfoVO: this.sessionData[0].distributorInfoVO,
        custShopInfoCheckDto: {
          latitude: this.formData.latitude, //经纬度
          longitude: this.formData.longitude, //经纬度
          fullName: this.formData.storeFullName, //门店全称
          searchTerm: this.formData.storeShortName, //门店简称
          shAddress: this.formData.address, //门店地址
          addressDesc: this.formData.addrDescribe, //地址描述
          tradingTypeId: this.formData.circleType, //商圈类型
          shopExpectedOpeningTime: this.formData.shopExpectedOpeningTime ? moment(this.formData.shopExpectedOpeningTime).format("YYYY-MM-DD") : null,
          bdId: Number(this.formData.bdId),
          bu1Id: Number(this.formData.bu1Id),
          areaLeaderId: this.formData.principal, //片区负责人
          shopPropertyId: this.formData.storefront,//"2091883", //店面性质
          // isCoreShop: this.formData.isCore, //是否核心门店
          isCoreShop: 0, //是否核心门店 默认传否
          annualSalesTotal: this.AllMaterialCooperationData.EstimatedStoreSize, //预计门店规模
          managerNameNew: this.AllMaterialCooperationData.StoreManagerName1, //门店经理姓名
          // isSinkChannel: this.formData.isSink, //是否下沉渠道1是，0否
          isSinkChannel:0, //默认否
          managerTelNew: this.AllMaterialCooperationData.StoreManagerTel1, //门店经理电话
          isPlanSpecial: this.formData.isSpecialShop, //是否海信专卖店
          content: this.formData.content, //申请原因
          headFilesStr: this.fileIds[0], //门店照片
          shopInfoDetailCheckList: this.final1, //物料组合作关系
        },
        id: this.sessionData[0].id,
        showSelect: this.sessionData[0].showSelect,
      };
      save(data1).then((res) => {
        this.spinning = false;
        if (res.data.code == 1) {
          this.$message.success(res.data.msg);
          this.tijiaoable = false;
          this.visibledata = false;
          return
        }
        if (res.data.code == 0) {
          this.$message.success("提交成功", 2);
          this.tijiaoable = false;
          delStoreUser();
          delStoreUser1();
          setTimeout(() => {
            this.$router.push("/basicInfo?type=3");
          }, 2000);
        } else {
          // this.$message.info(res.data.msg, 2);
          this.tijiaoable = false;
        }
      });
      this.visibledata = false;
    },
    // 点击提交弹窗的取消
    hideModal3() {
      this.final1=[]
      this.visibledata = false;
    },

    // 是否做展台变更
    isBoothChange(e) {
      if (e != 14923253081) {
        this.formData.projectRequirements = "";
        this.rules = {
          ownedBusiness: [
            {
              required: true,
              message: "请选择所属商家",
              trigger: ["change", "blur"],
            },
          ],
          marketLevel: [
            {
              required: true,
              message: "请选择市场级别",
              trigger: ["change", "blur"],
            },
          ],
          storeFullName: [
            {
              required: true,
              message: "请输入门店全称",
              trigger: ["change", "blur"],
            },
          ],
          storeShortName: [
            {
              required: true,
              message: "请正确输入门店简称",
              trigger: ["change", "blur"],
              max: 10,
            },
          ],
          province: [
            {
              required: true,
              message: "请选择省",
              trigger: ["change", "blur"],
            },
          ],
          city: [
            {
              required: true,
              message: "请选择市",
              trigger: ["change", "blur"],
            },
          ],
          area: [
            {
              required: true,
              message: "请选择区县",
              trigger: ["change", "blur"],
            },
          ],
          street: [
            {
              required: true,
              message: "请选择乡镇街道",
              trigger: ["change", "blur"],
            },
          ],
          // marketLv: [{
          //     required: true,
          //     message: "请选择市场级别",
          //     trigger: ["change", "blur"],
          // }],
          address: [
            {
              required: true,
              message: "请选择门店地址",
              trigger: ["change", "blur"],
            },
          ],
          addrDescribe: [{
              validator: this.checkAddrDescribe,
              trigger: ["change", "blur"],
          }],
          //     AddressDescription: [{
          //     required: true,
          //     message: "请输入地址描述",
          //     trigger: ["change", "blur"],
          // }],
          //     ManagerName: [{
          //     required: true,
          //     message: "请输入经理姓名",
          //     trigger: ["change", "blur"],
          // }],
          //  ManagerPhone: [{
          //     required: true,
          //     message: "请输入经理电话",
          //     trigger: ["change", "blur"],
          // }],
          marketingCenterId: [
            {
              required: true,
              message: "请选择营销中心办事处",
              trigger: ["change", "blur"],
            },
          ],
          postCode: [
            {
              required: true,
              message: "请输入邮政编码",
              trigger: ["change", "blur"],
            },
            {
              validator: this.isPostCode,
              message: "请输入正确的邮政编码",
              trigger: ["change", "blur"],
            },
          ],
          cityLv: [
            {
              required: true,
              message: "请选择城市级别",
              trigger: ["change", "blur"],
            },
          ],
          storefront: [
            {
              required: true,
              message: "请选择店面性质",
              trigger: ["change", "blur"],
            },
          ],
          // isSink: [
          //   {
          //     required: true,
          //     message: "请选择是否下沉渠道",
          //     trigger: ["change", "blur"],
          //   },
          // ],
          storeCategory: [
            {
              required: true,
              message: "请选择门店类别",
              trigger: ["change", "blur"],
            },
          ],
          // isCore: [
          //   {
          //     required: true,
          //     message: "请选择是否核心门店",
          //     trigger: ["change", "blur"],
          //   },
          // ],
          principalName: [
            {
              required: true,
              message: "请选择片区负责人",
              trigger: ["change", "blur"],
            },
          ],
          isBooth: [
            {
              required: true,
              message: "请选择是否做展台",
              trigger: ["change", "blur"],
            },
          ],
          content: [
            {
              required: true,
              message: "请输入申请原因",
              trigger: ["change", "blur"],
            },
          ],
          projectRequirements: [
            {
              required: false,
              message: "请输入项目需求名称",
              trigger: ["change", "blur"],
            },
          ],
        };
      } else {
        this.rules = {
          ownedBusiness: [
            {
              required: true,
              message: "请选择所属商家",
              trigger: ["change", "blur"],
            },
          ],
          marketLevel: [
            {
              required: true,
              message: "请选择市场级别",
              trigger: ["change", "blur"],
            },
          ],
          storeFullName: [
            {
              required: true,
              message: "请输入门店全称",
              trigger: ["change", "blur"],
            },
          ],
          storeShortName: [
            {
              required: true,
              message: "请输入门店简称",
              trigger: ["change", "blur"],
            },
          ],
          province: [
            {
              required: true,
              message: "请选择省",
              trigger: ["change", "blur"],
            },
          ],
          city: [
            {
              required: true,
              message: "请选择市",
              trigger: ["change", "blur"],
            },
          ],
          area: [
            {
              required: true,
              message: "请选择区县",
              trigger: ["change", "blur"],
            },
          ],
          street: [
            {
              required: true,
              message: "请选择乡镇街道",
              trigger: ["change", "blur"],
            },
          ],
          // marketLv: [{
          //     required: true,
          //     message: "请选择市场级别",
          //     trigger: ["change", "blur"],
          // }],
          address: [
            {
              required: true,
              message: "请选择门店地址",
              trigger: ["change", "blur"],
            },
          ],
          addrDescribe: [{
              validator: this.checkAddrDescribe,
              trigger: ["change", "blur"],
          }],
          // AddressDescription: [{
          // required: true,
          // message: "请输入地址描述",
          // trigger: ["change", "blur"],
          // }],
          //     ManagerName: [{
          //     required: true,
          //     message: "请输入经理姓名",
          //     trigger: ["change", "blur"],
          // }],
          //  ManagerPhone: [{
          //     required: true,
          //     message: "请输入经理电话",
          //     trigger: ["change", "blur"],
          // }],
          postCode: [
            {
              required: true,
              message: "请输入邮政编码",
              trigger: ["change", "blur"],
            },
            {
              validator: this.isPostCode,
              message: "请输入正确的邮政编码",
              trigger: ["change", "blur"],
            },
          ],
          cityLv: [
            {
              required: true,
              message: "请选择城市级别",
              trigger: ["change", "blur"],
            },
          ],
          storefront: [
            {
              required: true,
              message: "请选择店面性质",
              trigger: ["change", "blur"],
            },
          ],
          // isSink: [
          //   {
          //     required: true,
          //     message: "请选择是否下沉渠道",
          //     trigger: ["change", "blur"],
          //   },
          // ],
          storeCategory: [
            {
              required: true,
              message: "请选择门店类别",
              trigger: ["change", "blur"],
            },
          ],
          isCore: [
            {
              required: true,
              message: "请选择是否核心门店",
              trigger: ["change", "blur"],
            },
          ],
          principalName: [
            {
              required: true,
              message: "请选择片区负责人",
              trigger: ["change", "blur"],
            },
          ],
          isBooth: [
            {
              required: true,
              message: "请选择是否做展台",
              trigger: ["change", "blur"],
            },
          ],
          content: [
            {
              required: true,
              message: "请输入申请原因",
              trigger: ["change", "blur"],
            },
          ],
          projectRequirements: [
            {
              required: true,
              message: "请输入项目需求名称",
              trigger: ["change", "blur"],
            },
          ],
        };
      }
    },
    // 办事处物料组变更
    wlzChange(code, item) {
      for (let it of item.wlzList) {
        if (code == it.code) {
          item.wlzName = it.name;
          item.wlz = it.code;
          break;
        }
      }
      let bscWlz = [];
      for (let item of this.bscList) {
        bscWlz.push({
          bsc: item.bscId,
          bscName: item.bscName,
          code: item.wlz,
          name: item.wlzName,
        });
      }
      this.bscWlz = bscWlz;
    },
    xsgmWlzChange(code, item) {
      for (let it of this.bscWlz) {
        if (code == it.code) {
          item.wlzName = it.name;
          break;
        }
      }
    },
    upload(info, idx) {
      if (info.file.status === "done") {
        if (info.file.response.message == "上传成功") {
          this.bscList[idx].file = [
            {
              name: info.file.name,
              id: info.file.response.businessId,
            },
          ];
        } else {
          this.$message.warning("上传失败");
        }
      }
    },
    delImg(idx) {
      this.bscList[idx].file = [];
    },
    timeChange(e, defaultTime, record) {
      record.time = defaultTime;
    },
    handleChangeStart(value, record) {
      if (record.endYear && record.startYear > record.endYear) {
        this.$message.warning("输入时间不能大于结束时间,请重新选择");
        record.startYear = "";
        return;
      }
    },
    handleChangeEnd(value, record) {
      if (record.startYear && record.endYear < record.startYear) {
        this.$message.warning("输入时间小于开始时间,请重新选择");
        record.endYear = "";
        return;
      }
    },
  },
};
