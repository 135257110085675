import { render, staticRenderFns } from "./index.vue?vue&type=template&id=2be0d5fa&scoped=true"
import script from "./index.js?vue&type=script&lang=js&external"
export * from "./index.js?vue&type=script&lang=js&external"
import style0 from "./index.less?vue&type=style&index=0&id=2be0d5fa&prod&lang=less&scoped=true&external"
import style1 from "./index-copy.less?vue&type=style&index=1&id=2be0d5fa&prod&lang=less&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2be0d5fa",
  null
  
)

export default component.exports