import { saveStore,getDetail, } from "./api"
import moment from "moment";
export const mixin = {
    data() {
        return {
            dialogTableVisible: false,

            submitLine: true,
            saveAble: false,
            materialFullInfo: []
        }
    },
    methods: {
        hideModalMap() {
            this.dialogTableVisible = false
            // console.log(this.$refs.bMap,"经纬度有吗？");
            this.formData.address = this.$refs.bMap.form.address
            this.formData.longitude=this.$refs.bMap.form.addrPoint.lng//经度
            this.formData.latitude=this.$refs.bMap.form.addrPoint.lat//纬度
            // console.log(this.formData)
            // 执行一遍校验
            this.$refs['formData'].validateField(['address'], (Error) => {
                if (!Error) {
                    //执行
                }
            });
        },
        saveStore() {
            this.$refs['formData'].validate(valid => {
                if (!valid) {
                    return false;
                } else{
                    if (!this.submitLine) {
                        return
                    }
                    // this.submitLine = false
                    let formFilter = {
                        "attachs": [{ "id": 14991692461 }],
                        "custInfoId": "22756571",//商户
                        "fullName": "shop-20220811-1",//全称
                        "searchTerm": "s2811-1",//简称
                        "provinceId": "21244",//省
                        "cityId": "19545",//市
                        "countyId": "19484",//区县
                        "townId": "19480",//乡镇
                        "postalCode": "274600",//邮编
                        "shAddress": "山东省青岛市市南区湛山街道镇江南路海信学校2009",//地址
                        "longitude": 120.372273,//经度
                        "latitude": 36.070659,//纬度
                        "marketLevelId": "17250",//市场级别
                        "addressDesc": "城阳描述",//地址描述
                        "tradingTypeId": "14925759199",//商圈类型
                        "areaLeaderId": "zqh8331",//片区负责人
                        "districtType": "1",//城市级别
                        //门店性质
                        "isCoreShop": "1",//是否核心门店
                        "totalAnnual": "12.00",//总年销售规模
                        "isSinkChannel": "1",//是否下沉渠道
                        "totalForcast": "12.00",//总预计门店规模
                        "isPlanSpecial": "1",//是否成为海信专卖店
                        "content": "123",//申请原因
                    }
                    let temp = this.ownedBusiness.find(i => i.name == this.formData.ownedBusinessText)
                    // debugger
                    formFilter.custInfoId = temp?.code||this.formData.ownedBusiness;
                    formFilter.fullName = this.formData.storeFullName;
                    formFilter.searchTerm = this.formData.storeShortName;
        
                    formFilter.provinceId = this.formData.province;
                    formFilter.cityId = this.formData.city;
                    formFilter.countyId = this.formData.area;
                    formFilter.townId = this.formData.street;
                    formFilter.longitude = this.formData.longitude;
                    formFilter.latitude = this.formData.latitude;
                    formFilter.shopExpectedOpeningTime = this.formData.shopExpectedOpeningTime ? moment(this.formData.shopExpectedOpeningTime).format("YYYY-MM-DD") : null;
                    formFilter.bdId = Number(this.formData.bdId);
                    formFilter.bu1Id = Number(this.formData.bu1Id);
                    formFilter.postalCode = this.formData.postCode;
                    formFilter.shAddress = this.formData.address;
                    formFilter.marketLevelId = this.formData.marketLevel;
                    formFilter.addressDesc = this.formData.addrDescribe;
                    formFilter.tradingTypeId = this.formData.circleType;
                    let leaedrObj = this.principal.find(i => i.name == this.formData.principalName)
                    formFilter.areaLeaderId = leaedrObj ? leaedrObj.code : '';
                    // console.log(leaedrObj);
                    // debugger
                    formFilter.districtType = this.formData.cityLv;
                    formFilter.isCoreShop = 0;
                    formFilter.totalAnnual = this.formData.znxsgm;
                    formFilter.isSinkChannel = 0;
                    formFilter.totalForcast = this.formData.zyjmdgm;
                    formFilter.isPlanSpecial = this.formData.isSpecialShop;
                    formFilter.content = this.formData.content;
                    formFilter.attachs[0].id = this.fileIds.length
                      ? this.fileIds[0].id
                      : this.fileList.id;
        
                    let paramaModel = {
                        "id": 0,
                        "custInfoId": "22756571",//商户
                        "marketLevelId": "17250",//市场级别
                        "content": "123",//申请原因
                        "fullName": "shop-20220811-1",//全称
                        "searchTerm": "s2811-1",//简称
                        "provinceId": "21244",//省
                        "cityId": "19545",//市
                        "countyId": "19484",//区县
                        "townId": "19480",//乡镇
                        "shAddress": "山东省青岛市市南区湛山街道镇江南路海信学校2009",//地址
                        "longitude": 120.372273,//经度
                        "latitude": 36.070659,//纬度
                        "addressDesc": "城阳描述",//地址描述
                        "postalCode": "274600",//邮编
                        "districtType": "1",//城市级别
                        "isSinkChannel": "1",//是否下沉渠道
                        "tradingTypeId": "14925759199",//商圈类型
                        "isCoreShop": "1",//是否核心门店
                        "areaLeaderId": "zqh8331",//片区负责人
                        "areaLeaderPhone": "13455650908",//片区负责人电话
                        "totalAnnual": "12.00",//总年销售规模
                        "totalForcast": "12.00",//总预计门店规模
                        "isPlanSpecial": "1",//是否成为海信专卖店
                        "source": "XTW",//数据来源
                        "attachs": [{ "id": 14991692461 }],
                        "shopInfoDetailCheckList": [
                            {
                                "orgId": "2026",//办事处
                                "orgName": "股份青岛综合",
                                "fgsName": "股份青岛",//分公司
                                "baseMatklId": "20160119010",//物料组
                                "baseMatklName": "IBOX",
                                "shopLevelId": "14170992126",//门店级别
                                "shopLevelName": "S-体验店（全品类）",
                                "shopArea": 12,//门店展厅面积
                                "modelCount": 12,//样机位数量
                                "managerName": "zzz",//门店经理姓名
                                "managerTel": "12345678911",//门店经理顶电话
                                "terminalOrgId": 10409,//营销中心办事处
                                "terminalOrgName": "杭州中心-义乌办事处",
                                "singleMeter": 12,//展台延米
                                "annualSales": "12",//年销售规模
                                "forcastSales": "12",//预计门店规模
                                "salesId":""
                            }
                        ],
                        "custShopInfoSalesCheckList": [],
                        "deleteDetailList": []
                    }
                    // debugger
                    let materialObj = this.materialObj
                    paramaModel.shopInfoDetailCheckList = this.MaterialCooperationData.map((i,idx) => ({
                        // orgId: this.infoAll.shopInfoDetailCheckList[idx].orgId,
                        // "orgId": i.infoObj?.orgId||i.banshichuId,//办事处
                        "orgId": i.banshichuId ||this.infoAll.shopInfoDetailCheckList[idx].orgId,//办事处`
                        "orgName": i.orgId,
                        "fgsName": i.materialObj?.fwOrgName||this.infoAll?.shopInfoDetailCheckList[idx]?.fgsName,//分公司
                        "baseMatklId": i.materialObj?.matklId||this.infoAll?.shopInfoDetailCheckList[idx]?.baseMatklId,//物料组
                        "baseMatklName": i.materialObj?.matklName||this.infoAll?.shopInfoDetailCheckList[idx]?.baseMatklName,
                        "shopLevelId": i.shopLevelId,//门店级别
                        "shopLevelName": i.shopLevel,
                        "shopArea": i.shopArea,//门店展厅面积
                        "modelCount": i.modelCount,//样机位数量
                        "managerName": i.managerName,//门店经理姓名
                        "managerTel": i.managerTel,//门店经理顶电话
                        "terminalOrgId": i.infoObj?.terminalOrgId|| i.yingxiaoid,//营销中心办事处
                        "terminalOrgName": i.terminalOrgId,
                        "singleMeter": i.singleMeter,//展台延米
                        "annualSales": i.annualSales,//年销售规模
                        "forcastSales": i.forcastSales,//预计门店规模
                        "salesId":i.salesId,
                        id:i.id
                    }))
                    if(paramaModel.shopInfoDetailCheckList.length==0){
                        this.$message.warning("添加物料组合作关系");
                        return;
                    }
        
                    let params = { ...paramaModel, ...formFilter }
                    
                    if (this.type == "edit") {
                        params.id = this.infoAll.id
                        
                        // getDetail(this.$route.query.id).then(res=>{
                        //     let info = res.data.data;
                        //     params.id = info.id
                        // })
                      }
                    this.saveAble = true;
                    saveStore(params).then(res => {
        
                        if (res.data.code == 0) {
                            this.$message.success("提交成功", 2);
                            setTimeout(() => {
                                if(this.$route.path === '/jurisdiction/storeAddEdit'){
                                    // 管辖的门店
                                    this.$router.push("/jurisdiction/store");
                                }else{
                                    // 分销的门店
                                    this.$router.push("/basicInfo?type=7");
                                }
                            }, 2000);
                            this.saveAble = false
                            // setTimeout(() => {
                            //     this.$router.push('/')
                            // }, 2000);
                        } else {
                            if(res.data.code == 1){
                                this.$message.warning(res.data.msg, 2);
                            }
                            // this.$message.info(res.data.msg, 2);
                            this.saveAble = false
        
                        }
                        this.submitLine = true
        
                    }).catch(err=>{
                        console.log(err);
                        this.saveAble = false;
                    })
                }
            });

            
        }
    }
}